import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    CitiesStateModel,
    CitiesGet,
    CitiesInsert,
    CitiesUpdate,
    CitiesDelete,
} from './cities.actions';
import { CityModel, PagedListModel } from 'app/models/all';
import { CitiesService } from 'app/services/all';

@State<CitiesStateModel>({
    name: 'cities',
    defaults: {},
})
@Injectable()
export class CitiesState {
    constructor(private citiesService: CitiesService) {}

    @Selector()
    static cities(
        state: CitiesStateModel
    ): PagedListModel<CityModel> | undefined {
        return state.cities;
    }

    @Action(CitiesGet)
    get(context: StateContext<CitiesStateModel>, params: CitiesGet) {
        return this.citiesService.get(params.request).pipe(
            tap((result: PagedListModel<CityModel>) => {
                context.patchState({
                    cities: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(CitiesInsert)
    insert(context: StateContext<CitiesStateModel>, params: CitiesInsert) {
        return this.citiesService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CitiesGet(previousState.filter));
            })
        );
    }

    @Action(CitiesUpdate)
    update(context: StateContext<CitiesStateModel>, params: CitiesUpdate) {
        return this.citiesService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CitiesGet(previousState.filter));
            })
        );
    }

    @Action(CitiesDelete)
    delete(context: StateContext<CitiesStateModel>, params: CitiesDelete) {
        return this.citiesService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CitiesGet(previousState.filter));
            })
        );
    }
}
