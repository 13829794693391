import { Injectable } from '@angular/core';
import { UnSub } from 'app/shared/extensions/unSub';

@Injectable({
    providedIn: 'root',
})
export class MapperService extends UnSub {
    constructor() {
        super();
    }
    map<T>(mapped: T, properties: { key: string; value: any }[]): T {
        const obj_properties: string[] = Object.getOwnPropertyNames(mapped);

        properties.forEach((prop) => {
            const founded = obj_properties.find(
                (item) => item.toLowerCase() == prop.key.toLowerCase()
            );
            if (founded?.length > 0)
                Object.defineProperty(mapped, founded, { value: prop.value });
        });
        return mapped;
    }
}
