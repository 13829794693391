import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private titleBehaviourSubject: BehaviorSubject<string>;

  constructor(private titleService: Title) {
    this.titleBehaviourSubject = new BehaviorSubject<string>(
      titleService.getTitle()
    );
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
    this.titleBehaviourSubject.next(title);
  }

  subscribe(next: (value: string) => void): Subscription {
    return this.titleBehaviourSubject.subscribe(next);
  }

  unsubscribe(): void {
    this.titleBehaviourSubject.unsubscribe();
  }
}
