import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormGroupService {
    constructor() {}

    validateFull(form: FormGroup, validation: any) {
        Object.keys(form.controls).forEach((control) => {
            form.get(control).setValidators([validation]);
            form.get(control).updateValueAndValidity();
            form.get(control).markAsTouched();
        });
    }

    validateWithout(form: FormGroup, controls: any[], validation: any) {
        Object.keys(form.controls).forEach((control) => {
            if (!controls.includes(control)) {
                form.get(control).setValidators([validation]);
                form.get(control).updateValueAndValidity();
                form.get(control).markAsTouched();
            }
        });
    }
}
