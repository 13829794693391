import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { StorageService } from './all';
import { BaseRequest } from '../models/requests/all';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export abstract class ApiService {
    baseApiUrl = environment.baseApiUrl;
    constructor(
        protected store: Store,
        protected httpClient: HttpClient,
        protected storageService: StorageService
    ) {}

    protected prepareHeaders(): HttpHeaders {
        const language = this.storageService.getItem('lang');

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            ...(language && { 'Content-Language': language! }),
        });
        const token = this.store.selectSnapshot((state) => state.auth.token);
        if (token === null || token === undefined) {
            return httpHeaders;
        }
        return httpHeaders.append('Authorization', `Bearer ${token}`);
    }

    // [Upload stream fails with 'Content-Type']
    protected prepareUploadHeaders(): HttpHeaders {
        const language = this.storageService.getItem('lang');

        const httpHeaders = new HttpHeaders({
            ...(language && { 'Content-Language': language! }),
        });
        const token = this.store.selectSnapshot((state) => state.auth.token);
        if (token === null) {
            return httpHeaders;
        }
        return httpHeaders.append('Authorization', `Bearer ${token}`);
    }

    protected prepareFormHeaders(): HttpHeaders {
        const language = this.storageService.getItem('lang');

        const httpHeaders = new HttpHeaders({
            ...(language && { 'Content-Language': language! })
        });
        const token = this.store.selectSnapshot((state) => state.auth.token);
        if (token === null) {
            return httpHeaders;
        }
        return httpHeaders.append('Authorization', `Bearer ${token}`);
    }

    protected sendGetRequest<T>(
        path: string,
        request?: BaseRequest
    ): Observable<T> {
        const params: any = request?.toParams();
        return this.httpClient.get<T>(this.withBaseApiUrl(path), {
            headers: this.prepareHeaders(),
            params: params
        });
    }

    protected sendGetRequestWithResponseTypeText(
        path: string,
        request?: BaseRequest
    ): Observable<string> {
        const params: any = request?.toParams();
        return this.httpClient.get(this.withBaseApiUrl(path), {
            headers: this.prepareHeaders(),
            responseType: "text",
            params: params
        });
    }

    protected sendPostRequest<T>(
        path: string,
        request?: BaseRequest
    ): Observable<T> {
        const params: any = request?.toParams();
        const body: any = request?.toBody();
        return this.httpClient.post<T>(this.withBaseApiUrl(path), body, {
            headers: this.prepareHeaders(),
            params: params
        });
    }

    protected sendPutRequest<T>(
        path: string,
        request?: BaseRequest
    ): Observable<T> {
        const params: any = request?.toParams();
        const body: any = request?.toBody();

        return this.httpClient.put<T>(this.withBaseApiUrl(path), body, {
            headers: this.prepareHeaders(),
            params: params,
        });
    }

    protected sendDeleteRequest<T>(
        path: string,
        request?: BaseRequest
    ): Observable<T> {
        const params: any = request?.toParams();
        const body: any = request?.toBody();

        return this.httpClient.delete<T>(this.withBaseApiUrl(path), {
            headers: this.prepareHeaders(),
            params: params,
            body: body,
        });
    }

    protected sendFormRequest<T>(
        path: string,
        request: FormData
    ): Observable<T> {
        return this.httpClient.post<T>(this.withBaseApiUrl(path), request, {
            headers: this.prepareFormHeaders()
        });
    }

    protected sendUpdateFormRequest<T>(
        path: string,
        request: FormData
    ): Observable<T> {
        return this.httpClient.put<T>(this.withBaseApiUrl(path), request, {
            headers: this.prepareFormHeaders()
        });
    }


    protected withBaseApiUrl(path: string): string {
        return `${this.baseApiUrl}/${path}`;
    }
}
