import { CityModel, PagedListModel } from 'app/models/all';
import {
    CitiesGetRequest,
    CitiesInsertRequest,
    CitiesUpdateRequest,
    CitiesDeleteRequest,
} from 'app/models/requests/all';

export class CitiesStateModel {
    cities?: PagedListModel<CityModel>;
    filter?: CitiesGetRequest;
}

export class CitiesGet {
    static readonly type = '[Cities] Get';
    constructor(public request?: CitiesGetRequest) {}
}

export class CitiesInsert {
    static readonly type = '[Cities] Insert';
    constructor(public request: CitiesInsertRequest) {}
}

export class CitiesUpdate {
    static readonly type = '[Cities] Update';
    constructor(public request: CitiesUpdateRequest) {}
}

export class CitiesDelete {
    static readonly type = '[Cities] Delete';
    constructor(public request: CitiesDeleteRequest) {}
}
