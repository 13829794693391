import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";
import { FuseCardModule } from "@fuse/components/card";
import { FuseNavigationModule } from "@fuse/components/navigation";
import { PipesModule } from "@fuse/pipes/pipes.module";
import { MY_FORMATS } from "app/constants/date-format";
import { ComponentsModule } from "app/layout/components/components.module";
import { FormControlErrorModule } from "app/shared/errors/form-control-error/form-control-error.module";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { DeletePromptModule } from "./modals/delete-prompt-modal/delete-prompt-modal.module";
import { AddressModule } from "./address/address/address.module";
import { NgApexchartsModule } from "ng-apexcharts";
import { QuillModule } from "ngx-quill";

@NgModule({
    imports: [
       PipesModule,
       FormControlErrorModule,
       FormsModule,
       CommonModule,
       ReactiveFormsModule,
       DeletePromptModule,
       MaterialFileInputModule,
       FuseNavigationModule,
       ComponentsModule,
       AddressModule
    ],
    exports: [
       PipesModule,
       FormControlErrorModule,
       FormsModule,
       CommonModule,
       ReactiveFormsModule,
       DeletePromptModule,
       MaterialFileInputModule,
       FuseNavigationModule,
       ComponentsModule,
       FuseCardModule,
       NgApexchartsModule,
       QuillModule,
       FuseCardModule
    ],
    providers: [
        provideMomentDateAdapter( MY_FORMATS, { useUtc: true }),
    ],
})
export class SharedModule {}
