import { SimpleItemModel } from 'app/models/all';
import {
    AddressesGetRequest,
    AddressesInsertRequest,
} from 'app/models/requests/all';

export class AddressesStateModel {
    addresses: SimpleItemModel[] | null;
    lastAddedAddressId: number | null;
}

export class AddressesGet {
    static readonly type = '[Addresses] Get';
    constructor(public request?: AddressesGetRequest) {}
}

export class AddressesInsert {
    static readonly type = '[Addresses] Insert';
    constructor(public request: AddressesInsertRequest) {}
}
