import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    AnnouncementAttachmentsGetByAnnouncementIdRequest,
    AnnouncementFileDeleteRequest,
    AnnouncementFileStorageBetByAnnouncementIdRequest,
    AnnouncementFileStorageInsertRequest,
} from 'app/models/requests/announcement-file-storage.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementFilesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    insert(request: AnnouncementFileStorageInsertRequest) {
        return this.sendPostRequest<any>('announcementfilestorage', request);
    }

    delete(request: AnnouncementFileDeleteRequest) {
        return this.sendDeleteRequest('announcementfilestorage', request).subscribe();
    }

    getByAnnouncementId(
        request: AnnouncementFileStorageBetByAnnouncementIdRequest
    ) {
        return this.sendGetRequest<any>(
            'announcementfilestorage/getbyannouncementid',
            request
        );
    }

    getAttachmentsById(
        request: AnnouncementAttachmentsGetByAnnouncementIdRequest
    ) {
        return this.sendGetRequest<any>(
            'announcementfilestorage/getattachmentsbyannouncementid',
            request
        );
    }
}
