import { AddressesInsertRequest } from 'app/models/requests/addresses.request';

export class NonAuthStateModel {
    lastAddressId?: number;
}

export class SignUpInsertAddress {
    static readonly type = '[Address] SignUp - Insert';
    constructor(public request?: AddressesInsertRequest) {}
}
