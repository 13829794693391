import { InstitutionActivityModel } from 'app/models/institution-activity.model';
import { InstitutionActivitiesService } from 'app/services/institution-activities.service';
import {
    InstitutionActivitiesGetByPreschoolId,
    InstitutionActivitiesStateModel,
} from './institution-activities.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InstitutionActivitiesGetByInstitutionIdRequest } from 'app/models/requests/institution-activities.request';
import { ActivityTypeModel } from 'app/models/activity-type.model';
import { tap } from 'rxjs';
import { ActivitiesStateModel } from '../all';

@State<InstitutionActivitiesStateModel>({
    name: 'institutionActivities',
    defaults: {},
})
@Injectable()
export class InstitutionActivitiesState {
    constructor(private _service: InstitutionActivitiesService) {}

    @Selector()
    static institutionActivities(
        state: InstitutionActivitiesStateModel
    ): InstitutionActivityModel[] | undefined {
        return state.institutionActivities;
    }

    @Action(InstitutionActivitiesGetByPreschoolId)
    get(
        context: StateContext<InstitutionActivitiesStateModel>,
        params: InstitutionActivitiesGetByPreschoolId
    ) {
        return this._service.getByInstitutionId(params.request).pipe(
            tap((result: InstitutionActivityModel[]) => {
                context.patchState({
                    institutionActivities: result,
                });
            })
        );
    }
}
