import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    AddressesStateModel,
    AddressesGet,
    AddressesInsert,
} from './addresses.actions';
import { AddressModel, SimpleItemModel } from 'app/models/all';
import { AddressesService } from 'app/services/all';

@State<AddressesStateModel>({
    name: 'addresses',
    defaults: {
        addresses: null,
        lastAddedAddressId: null,
    },
})
@Injectable()
export class AddressesState {
    constructor(private addressesService: AddressesService) {}

    @Selector()
    static addresses(state: AddressesStateModel): SimpleItemModel[] | null {
        return state.addresses;
    }

    @Selector()
    static lastAddedAddressId(state: AddressesStateModel): number | null {
        return state.lastAddedAddressId;
    }

    @Action(AddressesGet)
    get(context: StateContext<AddressesStateModel>) {
        return this.addressesService.get().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    addresses: result,
                });
            })
        );
    }

    @Action(AddressesInsert)
    insert(
        context: StateContext<AddressesStateModel>,
        params: AddressesInsert
    ) {
        return this.addressesService.insert(params.request).pipe(
            tap((result: AddressModel) => {
                context.patchState({
                    lastAddedAddressId: result.id,
                });

                context.dispatch(new AddressesGet());
            })
        );
    }
}
