import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ParentshipsByChildId, ParentshipsGetInfoRequest } from 'app/models/requests/parentships.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ParentshipsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    info(request: ParentshipsGetInfoRequest) {
        return this.sendGetRequest<any>('parentships/child-info', request);
    }

    checkChildsParent(request: ParentshipsByChildId){
        return this.sendGetRequest<any>('parentships/check-childs-parent', request);
    }
}
