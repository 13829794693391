import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressesInsertRequest } from 'app/models/requests/addresses.request';
import { ResetPasswordRequest } from 'app/models/requests/reset-password.request';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class NonAuthService {
    constructor(private httpClient: HttpClient) {}
    /* check user exists on registration form */

    checkEmailExist(email: string) {
        return this.httpClient.get(
            environment.baseApiUrl + '/nonauth/emailexist?email=' + email
        );
    }

    getCountries() {
        return this.httpClient.get(
            environment.baseApiUrl + '/nonauth/countries'
        );
    }

    getCities(countryId: number) {
        return this.httpClient.get(
            environment.baseApiUrl + '/nonauth/cities?countryId=' + countryId
        );
    }

    insertAddress(request: AddressesInsertRequest) {
        return this.httpClient.post(
            environment.baseApiUrl + '/nonauth/post-address',
            request
        );
    }

    resetPassword(request: ResetPasswordRequest) {
        return this.httpClient.put(
            environment.baseApiUrl + '/nonauth/reset-password',
            request,
            {}
        );
    }

    requestPasswordReset(email: string) {
        return this.httpClient.put(
            environment.baseApiUrl +
                '/nonauth/request-password-reset?email=' +
                email,
            null,
            {}
        );
    }

    validatePasswordResetToken(token: string) {
        return this.httpClient.put<string>(
            environment.baseApiUrl +
                '/nonauth/validate-password-reset-token?token=' +
                token,
            null,
            { responseType: 'text' as 'json' }
        );
    }

    confirmEmailUpdate(token: string) {
        return this.httpClient.put<string>(
            environment.baseApiUrl +
                '/nonauth/confirm-email-update?token=' +
                token,
            null,
            { responseType: 'text' as 'json' }
        );
    }
}
