import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'fileSizeConverter'})export class FileSizeConverterPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(parseFloat(String(value))) || !isFinite(value)) 
      return '-';

    return this.formatBytes(value);
  }
  private formatBytes(bytes: number): string {
    if (bytes === 0) 
      return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let count = 0;
    while(bytes >= 1024){
      count++;
      bytes = bytes / 1024;
    }
    return (bytes.toFixed(2) + ' ' + sizes[count]);
  }
}