import { PagedListModel } from 'app/models/all';
import {
    AppUserInsertRequest,
    AppUsersDeactivateRequest,
    AppUsersGetRequest,
    AppUserUpdateRequest,
} from 'app/models/requests/all';

export class AppUsersStateModel {
    users?: PagedListModel<any>;
    filter?: AppUsersGetRequest;
}

export class AppUsersGet {
    static readonly type = '[AppUsers] Get';
    constructor(public request?: AppUsersGetRequest) {}
}

export class AppUsersInsert {
    static readonly type = '[AppUsers] Insert';
    constructor(public request: AppUserInsertRequest) {}
}

export class AppUsersUpdate {
    static readonly type = '[AppUsers] Update';
    constructor(public request: AppUserUpdateRequest) {}
}

export class AppUserDeactivate {
    static readonly type = '[AppUsers] Deactivate';
    constructor(public request: AppUsersDeactivateRequest) {}
}
