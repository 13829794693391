import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import { SearchService, StorageService } from 'app/services/all';
import { HeaderSearchRequest } from 'app/models/requests/header-search.request';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncementInfoComponent } from './announcement-info/announcement-info.component';
import { EventInfoComponent } from './event-info/event-info.component';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce = 300;
    @Input() minLength = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened = false;
    resultSets: any;
    searchControl: UntypedFormControl = new UntypedFormControl();
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _dialog: MatDialog,
        private _service: SearchService,
        private _storage: StorageService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened,
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    placeholder = '';

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {
                    if (value.length < 2) return;

                    this.placeholder = value;
                    this._service
                        .searchHeader(
                            new HeaderSearchRequest(
                                value,
                                this._storage.getInstitutionId(),
                                this._storage.getUnitId()
                            )
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((dataSets) => {
                            this.resultSets = dataSets;
                        });
                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    /*                     if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }
 */
                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter((value) => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                this.resultSets = value;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Modal for announcements
     */

    openAnnouncement(model: any) {
        const _dialogRef = this._dialog.open(AnnouncementInfoComponent, {
            data: model,
            disableClose: false,
            maxWidth: '95vh',
            maxHeight: '100vh',
            width: '95%',
        });

        _dialogRef
            .afterOpened()
            .subscribe(() => this.searchControl.patchValue(this.placeholder));

        _dialogRef.afterClosed().subscribe(() => {
            this.resultSets = null;
        });

        this.close();
    }

    openEvent(model: any) {
        const _dialogRef = this._dialog.open(EventInfoComponent, {
            data: model,
            disableClose: false,
            maxWidth: '95vh',
            maxHeight: '100vh',
            width: '95%',
        });

        _dialogRef
            .afterOpened()
            .subscribe(() => this.searchControl.patchValue(this.placeholder));

        _dialogRef.afterClosed().subscribe(() => {
            this.resultSets = null;
        });

        this.close();
    }

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if (event.code === 'Escape') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');
        this.resultSets = null;
        // Close the search
        this.opened = false;
    }

    /**
     * Download file
     *
     */

    download(url: string) {
        window.open(url, '_blank');
        this.resultSets = null;
        this.searchControl.patchValue(this.placeholder);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
