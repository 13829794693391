import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    EventTypeDeleteRequest,
    EventTypeInsertRequest,
    EventTypeUpdateRequest,
} from 'app/models/requests/event-type.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class EventTypesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getAll() {
        return this.sendGetRequest('eventtypes/getall');
    }

    update(request: EventTypeUpdateRequest) {
        return this.sendPutRequest('eventtypes', request);
    }

    insert(request: EventTypeInsertRequest) {
        return this.sendPostRequest('eventtypes', request);
    }

    delete(request: EventTypeDeleteRequest) {
        return this.sendDeleteRequest('eventtypes', request);
    }
}
