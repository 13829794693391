import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    ProgramsStateModel,
    ProgramsGet,
    ProgramsInsert,
    ProgramsUpdate,
    ProgramsDelete,
} from './programs.actions';
import { ProgramModel } from 'app/models/all';
import { ProgramsService } from 'app/services/programs.service';
@State<ProgramsStateModel>({
    name: 'programs',
    defaults: {
        programs: [],
    },
})
@Injectable()
export class ProgramsState {
    constructor(private programsService: ProgramsService) {}

    @Selector()
    static programs(state: ProgramsStateModel): any | undefined {
        return state.programs;
    }

    @Action(ProgramsGet)
    get(context: StateContext<ProgramsStateModel>, params: ProgramsGet) {
        return this.programsService.get(params.request).pipe(
            tap((result: any) => {
                context.patchState({
                    programs: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(ProgramsInsert)
    insert(context: StateContext<ProgramsStateModel>, params: ProgramsInsert) {
        return this.programsService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new ProgramsGet(previousState.filter));
            })
        );
    }

    @Action(ProgramsUpdate)
    update(context: StateContext<ProgramsStateModel>, params: ProgramsUpdate) {
        return this.programsService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new ProgramsGet(previousState.filter));
            })
        );
    }

    @Action(ProgramsDelete)
    delete(context: StateContext<ProgramsStateModel>, params: ProgramsDelete) {
        return this.programsService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new ProgramsGet(previousState.filter));
            })
        );
    }
}
