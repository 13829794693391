import { DocumentTypeModel, PagedListModel } from 'app/models/all';
import {
    DocumentTypeGetRequest,
    DocumentTypeInsertRequest,
    DocumentTypeUpdateRequest,
    DocumentTypeDeleteRequest,
} from 'app/models/requests/all';

export class DocumentTypesStateModel {
    documents?: PagedListModel<DocumentTypeModel>;
    filter?: DocumentTypeGetRequest;
}

export class DocumentTypesGet {
    static readonly type = '[DocumentTypes] Get';
    constructor(public request?: DocumentTypeGetRequest) {}
}

export class DocumentTypesInsert {
    static readonly type = '[DocumentTypes] Insert';
    constructor(public request: DocumentTypeInsertRequest) {}
}

export class DocumentTypesUpdate {
    static readonly type = '[DocumentTypes] Update';
    constructor(public request: DocumentTypeUpdateRequest) {}
}

export class DocumentTypesDelete {
    static readonly type = '[DocumentTypes] Delete';
    constructor(public request: DocumentTypeDeleteRequest) {}
}
