import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';

import { StorageService } from './services/all';
import { environment } from 'environments/environment';

export function AppInitializerFactory(
    translateService: TranslateService,
    storageService: StorageService,
    injector: Injector
) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );

            locationInitialized.then(() => {
                let preferredLanguage = storageService.getItem('lang') ?? 'bs';
                if (preferredLanguage) {
                    translateService.use(preferredLanguage);
                } else {
                    storageService.setItem('lang', environment.defaultLanguage);
                    translateService.use(environment.defaultLanguage);
                    preferredLanguage = environment.defaultLanguage;
                }

                translateService.setDefaultLang(preferredLanguage);
                translateService.use(preferredLanguage).subscribe({
                    next: () => {
                        if (!environment.production) {
                            console.info();
                        }
                    },
                    error: () => {
                        if (!environment.production) {
                            console.error();
                        }
                    },
                    complete: () => {
                        resolve(null);
                    },
                });
            });
        });
}
