import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UnSub } from 'app/shared/extensions/unSub';

@Component({
    selector: 'app-question-modal',
    templateUrl: './question-modal.component.html',
    styleUrls: ['./question-modal.component.scss'],
})
export class QuestionModalComponent extends UnSub implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<QuestionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string,
        public router: Router
    ) {
        super();
    }
    ngOnInit(): void {}
    closeDialog() {
        this.dialogRef.close();
    }
    navigate() {
        this.router.navigate(['newenrollment']);
        this.closeDialog();
    }
}
