import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { GetByIdRequest } from 'app/models/requests/by-id.request';
import { InstitutionConfigModel } from 'app/models/all';
import {
    GetTermsAndConditionsRequest,
    UpdateTermsAndConditionsRequest,
} from 'app/models/requests/all';
import { InstitutionConfigGetRequest, InstitutionConfigInsertRequest, InstitutionConfigUpdateRequest } from 'app/models/requests/institution-config.request';

@Injectable({
    providedIn: 'root',
})
export class InstitutionConfigService extends ApiService {
    constructor(
        protected override httpClient: HttpClient,
        protected override store: Store,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getByInstitutionId(request: GetByIdRequest) {
        return this.sendGetRequest<InstitutionConfigModel>(
            'institutionconfig',
            request
        );
    }

    updateInstitutionConfig(request : InstitutionConfigUpdateRequest){
        return this.sendPutRequest('institutionconfig', request);
    }

    updateTermsAndConditions(request: UpdateTermsAndConditionsRequest) {
        return this.sendPostRequest(
            'institutionconfig/termsandconditions',
            request
        );
    }

    getTermsAndConditions(request: GetTermsAndConditionsRequest) {
        return this.sendGetRequest(
            'institutionconfig/getlanguagetermsandconditions',
            request
        );
    }

    updateMonitoringForParents(request:InstitutionConfigGetRequest){
        return this.sendPutRequest(
            'institutionconfig',
            request
        );
    }

    getBySchoolYearId(schoolYearId : number){
        return this.sendGetRequest<InstitutionConfigModel>("institutionconfig/getbyschoolyearid?schoolyearid=" + schoolYearId)
    }
}
