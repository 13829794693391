import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    AnnouncementsStateModel,
    AnnouncementsGet,
    AnnouncementsInsert,
    AnnouncementsUpdate,
    AnnouncementsDelete,
    AnnouncementsGetPublic,
} from './announcements.actions';
import { AnnouncementModel, PagedListModel } from 'app/models/all';
import { AnnouncementsService } from 'app/services/all';

@State<AnnouncementsStateModel>({
    name: 'announcements',
    defaults: {},
})
@Injectable()
export class AnnouncementsState {
    constructor(private announcementsService: AnnouncementsService) {}

    @Selector()
    static announcements(
        state: AnnouncementsStateModel
    ): PagedListModel<AnnouncementModel> | undefined {
        return state.announcements;
    }

    @Action(AnnouncementsGet)
    get(
        context: StateContext<AnnouncementsStateModel>,
        params: AnnouncementsGet
    ) {
        return this.announcementsService.get(params.request).pipe(
            tap((result: PagedListModel<AnnouncementModel>) => {
                context.patchState({
                    announcements: result,
                    filter: params.request,
                });
            })
        );
    }


    @Action(AnnouncementsGetPublic)
    getPublic(
        context: StateContext<AnnouncementsStateModel>,
        params: AnnouncementsGetPublic
    ) {
        return this.announcementsService.getPublic(params.request).pipe(
            tap((result: PagedListModel<AnnouncementModel>) => {
                context.patchState({
                    announcements: result,
                    filter: null,
                });
            })
        );
    }



    @Action(AnnouncementsInsert)
    insert(
        context: StateContext<AnnouncementsStateModel>,
        params: AnnouncementsInsert
    ) {
        return this.announcementsService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new AnnouncementsGet(previousState.filter));
            })
        );
    }

    @Action(AnnouncementsUpdate)
    update(
        context: StateContext<AnnouncementsStateModel>,
        params: AnnouncementsUpdate
    ) {
        return this.announcementsService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new AnnouncementsGet(previousState.filter));
            })
        );
    }

    @Action(AnnouncementsDelete)
    delete(
        context: StateContext<AnnouncementsStateModel>,
        params: AnnouncementsDelete
    ) {
        return this.announcementsService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new AnnouncementsGet(previousState.filter));
            })
        );
    }
}
