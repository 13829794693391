import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { AuthState } from 'app/stores/all';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public HubConnection!: HubConnection;

  constructor(private _store : Store) { }

  async initializeHubConnections() {
    try {
      this.HubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.hub}?userId=${this._store.selectSnapshot(AuthState.details).Id}`)
      .configureLogging(LogLevel.Information)
        .build();


      await Promise.all([
        this.HubConnection.start(),
      ]);
    } catch (error) {
      console.error("Error starting hub connections:", error);
    }
  }
}
