<div class="-m-5">
    <div class="-pt-2" class="flex justify-end">
        <button mat-icon-button class="mb-2 mt-2 mr-1" (click)="closeDialog()" color="light">
            <mat-icon class="">close</mat-icon>
        </button>
    </div>

    <div class="slider">
        <div>
            <div (click)="goToPrevious()" *ngIf="shouldShowArrows" class="leftArrow">❰</div>
            <div (click)="goToNext()" *ngIf="shouldShowArrows" class="rightArrow">❱</div>
        </div>
        <img class="object-cover rounded-2xl" [(src)]="currentPicture" />
    </div>
</div>
