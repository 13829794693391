import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    PreschoolInstitutionsStateModel,
    PreschoolInstitutionsGet,
    PreschoolInstitutionsInsert,
    PreschoolInstitutionsUpdate,
    PreschoolInstitutionsDelete,
    PreschoolInstitutionsGetByUserId,
} from './preschool-institutions.actions';
import { PagedListModel, PreschoolInstitutionModel } from 'app/models/all';
import { PreschoolInstitutionsService } from 'app/services/all';

@State<PreschoolInstitutionsStateModel>({
    name: 'preschoolInstitutions',
    defaults: {},
})
@Injectable()
export class PreschoolInstitutionsState {
    constructor(
        private preschoolinstitutionsService: PreschoolInstitutionsService
    ) {}

    @Selector()
    static preschoolinstitutions(
        state: PreschoolInstitutionsStateModel
    ): PagedListModel<PreschoolInstitutionModel> | undefined {
        return state.preschoolInstitutions;
    }

    @Action(PreschoolInstitutionsGet)
    get(
        context: StateContext<PreschoolInstitutionsStateModel>,
        params: PreschoolInstitutionsGet
    ) {
        return this.preschoolinstitutionsService.get(params.request).pipe(
            tap((result: PagedListModel<PreschoolInstitutionModel>) => {
                context.patchState({
                    preschoolInstitutions: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(PreschoolInstitutionsGetByUserId)
    getByUserId(
        context: StateContext<PreschoolInstitutionsStateModel>,
        params: PreschoolInstitutionsGetByUserId
    ) {
        return this.preschoolinstitutionsService
            .getByUserId(params.request)
            .pipe(
                tap((result: PagedListModel<PreschoolInstitutionModel>) => {
                    context.patchState({
                        preschoolInstitutions: result,
                        filter: params.request,
                    });
                })
            );
    }

    @Action(PreschoolInstitutionsInsert)
    insert(
        context: StateContext<PreschoolInstitutionsStateModel>,
        params: PreschoolInstitutionsInsert
    ) {
        return this.preschoolinstitutionsService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(
                    new PreschoolInstitutionsGet(previousState.filter)
                );
            })
        );
    }

    @Action(PreschoolInstitutionsUpdate)
    update(
        context: StateContext<PreschoolInstitutionsStateModel>,
        params: PreschoolInstitutionsUpdate
    ) {
        return this.preschoolinstitutionsService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(
                    new PreschoolInstitutionsGet(previousState.filter)
                );
            })
        );
    }

    @Action(PreschoolInstitutionsDelete)
    delete(
        context: StateContext<PreschoolInstitutionsStateModel>,
        params: PreschoolInstitutionsDelete
    ) {
        return this.preschoolinstitutionsService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(
                    new PreschoolInstitutionsGet(previousState.filter)
                );
            })
        );
    }
}
