import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ActiveRoleEnum } from 'app/models/enums/roles.enum';
import { StorageService } from 'app/services/storage.service';
import { TokenService } from 'app/services/token.service';
import { AuthState } from 'app/stores/all';

@Injectable({
    providedIn: 'root',
})
export class LandingGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
        private tokenService: TokenService,
        private storageService: StorageService
    ) {}
    canActivate(): boolean {
        const token = this.store.selectSnapshot(AuthState.token);
        if (token && !this.tokenService.isExpired(token.toString())) {
            if (
                this.storageService.getActiveRole() ==
                ActiveRoleEnum.SuperAdministrator
            )
                this.router.navigate(['/dashboard']);
            return true;
        }
    }
}
