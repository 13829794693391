import { Component, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnSub } from 'app/shared/extensions/unSub';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class DetailsComponent extends UnSub implements OnInit, OnDestroy {
    constructor(
        /* _transfer can be announcement or event detail.. */
        @Inject(MAT_DIALOG_DATA)
        public _transfer: { files: string[]; currentPicture: string },
        public _dialogRef: MatDialogRef<DetailsComponent>
    ) {
        super();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {
      if (event.key === 'ArrowLeft') {
        this.goToPrevious();
      }
      if (event.key === 'ArrowRight') {
        this.goToNext();
      }

    }
  
    currentPicture = '';
    shouldShowArrows: boolean;
    // --------------------------------------------------------------
    // @ Lifecycle hooks
    // --------------------------------------------------------------

    ngOnInit(): void {
        this.currentPicture = this._transfer.currentPicture;

        this.currentIndex = this._transfer?.files?.findIndex(
            (item) => item == this.currentPicture
        );

        this.shouldShowArrows = this._transfer?.files?.length > 1;
    }

    // ---------------------------------------------------------------
    // @ Public methods
    // ---------------------------------------------------------------

    currentIndex = 0;
    timeoutId?: number;

    ngOnDestroy() {
        window.clearTimeout(this.timeoutId);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    goToPrevious(): void {
        if (this.currentIndex > 0) {
            const newIndex = this.currentIndex - 1;
            this.currentPicture = this._transfer.files[newIndex];
            this.currentIndex = newIndex;
        } else {
            const newIndex = this._transfer.files.length - 1;
            this.currentPicture = this._transfer.files[newIndex];
            this.currentIndex = newIndex;
        }
    }

    goToNext(): void {
        if (this.currentIndex < this._transfer.files.length - 1) {
            const newIndex = this.currentIndex + 1;
            this.currentPicture = this._transfer.files[newIndex];
            this.currentIndex = newIndex;
        } else {
            this.currentPicture = this._transfer.files[0];
            this.currentIndex = 0;
        }
    }
}
