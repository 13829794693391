import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PagedListModel, PlaceholderRequestModel } from 'app/models/all';
import {
    PlaceholderDeleteRequest,
    PlaceholderGetRequest,
    PlaceholderInsertRequest,
    PlaceholderStatusHistoryRequest,
    PlaceholderUpdateRequest,
} from 'app/models/requests/all';
import { ApiService, StorageService } from './all';

@Injectable({
    providedIn: 'root',
})
export class PlaceholderRequestsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: PlaceholderGetRequest) {
        return this.sendGetRequest<PagedListModel<PlaceholderRequestModel>>(
            'placeholderrequests/filter',
            request
        );
    }

    getChangeHistory(request: PlaceholderStatusHistoryRequest) {
        return this.sendGetRequest<any>(
            'placeholderrequests/getchangehistory',
            request
        );
    }

    insert(request: PlaceholderInsertRequest) {
        return this.sendPostRequest<PlaceholderRequestModel>(
            'placeholderrequests',
            request
        );
    }

    update(request: PlaceholderUpdateRequest) {
        return this.sendPutRequest<PlaceholderRequestModel>(
            'placeholderrequests',
            request
        );
    }

    delete(request: PlaceholderDeleteRequest) {
        return this.sendDeleteRequest<any>('placeholderrequests', request);
    }
}
