import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationModel, PagedListModel } from 'app/models/all';
import {
    NotificationsDeleteRequest,
    NotificationsGetRequest,
    NotificationsMarkAllAsReadRequest,
} from 'app/models/requests/all';
import { UnSub } from 'app/shared/extensions/unSub';
import { takeUntil } from 'rxjs';
import { NotificationService } from 'app/services/notification.service';
import { AuthState } from 'app/stores/all';
import { timeFormat } from 'app/constants/date-format';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent extends UnSub implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<IndexComponent>,
        public store: Store,
        public formBuilder: FormBuilder,
        public notificationsService: NotificationService,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public notifications: NotificationModel[]
    ) {
        super();
    }
    timeFormat = timeFormat;
    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    ngOnInit(): void {
        this.loadData();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    delete(notificationId: number): void {
        this.notificationsService.remove(
            new NotificationsDeleteRequest(
                this.store.selectSnapshot(AuthState.details).Id,
                notificationId
            )
        );
        this.loadData();
    }

    markAllAsRead(): void {
        this.notificationsService.markAllAsRead(
            new NotificationsMarkAllAsReadRequest(
                this.store.selectSnapshot(AuthState.details).Id
            )
        );

        this.loadData();

        // Mark all as read
    }

    loadData() {
        this.notificationsService
            .get(
                new NotificationsGetRequest(
                    this.store.selectSnapshot(AuthState.details).Id,
                    null,
                    1,
                    100
                )
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((notifications: PagedListModel<NotificationModel>) => {
                // Load the notifications
                this.notifications = notifications?.items;
            });
    }

    getNotificationIcon(type: number): string {
        if (type == 1) return '/assets/img/annoucements/info.png';
        if (type == 2) return '/assets/img/annoucements/calendar.png';
        if (type == 3) return '/assets/img/annoucements/warning.png';
        if (type == 4) return '/assets/img/annoucements/request.png';
    }
}
