import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import {
    BusinessUnitProgramModel,
} from '../models/all';
import {
    BusinessUnitsProgramsGetRequest,
    BusinessUnitsProgramsDeleteRequest,
    BusinessUnitsProgramsInsertRequest,
    BusinessUnitsProgramsUpdateRequest,
    BusinessUnitsProgramsGetPriceRequest,
    BusinessUnitsProgramsGetByInstitutionRequest,
} from '../models/requests/all';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitsProgramsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getByPreschoolId(request: BusinessUnitsProgramsGetRequest) {
        return this.sendGetRequest<BusinessUnitProgramModel[]>(
            'businessunitprograms/getbyparam',
            request
        );
    }

    insert(request: BusinessUnitsProgramsInsertRequest) {
        return this.sendPostRequest('businessunitprograms', request);
    }

    update(request: BusinessUnitsProgramsUpdateRequest) {
        return this.sendPutRequest<BusinessUnitProgramModel>(
            'businessunitprograms',
            request
        );
    }

    delete(request: BusinessUnitsProgramsDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('businessunitprograms', request);
    }

    getPrice(request: BusinessUnitsProgramsGetPriceRequest) {
        return this.sendGetRequest<any>('businessunitprograms/getprice', request);
    }

    getBusinessUnitsProgramsByInsitutionId(request: BusinessUnitsProgramsGetByInstitutionRequest) {
        return this.sendGetRequest<BusinessUnitProgramModel[]>(
            'businessunitprograms/getbusinessunitsbyinstitutionid',
            request
        );
    }
}
