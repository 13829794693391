import { AnnouncementModel, PagedListModel } from 'app/models/all';
import {
    AnnouncementsGetRequest,
    AnnouncementsInsertRequest,
    AnnouncementsUpdateRequest,
    AnnouncementsDeleteRequest,
    AnnouncementsPublicGetRequest,
} from 'app/models/requests/all';

export class AnnouncementsStateModel {
    announcements?: PagedListModel<AnnouncementModel>;
    filter?: AnnouncementsGetRequest;
}

export class AnnouncementsGet {
    static readonly type = '[Announcements] Get';
    constructor(public request?: AnnouncementsGetRequest) {}
}

export class AnnouncementsGetPublic {
    static readonly type = '[Announcements] GetPublic';
    constructor(public request?: AnnouncementsPublicGetRequest) {}
}

export class AnnouncementsInsert {
    static readonly type = '[Announcements] Insert';
    constructor(public request: AnnouncementsInsertRequest) {}
}

export class AnnouncementsUpdate {
    static readonly type = '[Announcements] Update';
    constructor(public request: AnnouncementsUpdateRequest) {}
}

export class AnnouncementsDelete {
    static readonly type = '[Announcements] Delete';
    constructor(public request: AnnouncementsDeleteRequest) {}
}
