import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { CountryModel, PagedListModel, SimpleItemModel } from '../models/all';
import { ApiService, StorageService } from './all';
import {
  CountriesGetRequest,
  CountriesInsertRequest,
  CountriesUpdateRequest,
  CountriesDeleteRequest,
} from '../models/requests/all';

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends ApiService {
  constructor(
    protected override store: Store,
    protected override httpClient: HttpClient,
    protected override storageService: StorageService
  ) {
    super(store, httpClient, storageService);
  }

  get(request?: CountriesGetRequest) {
    return this.sendGetRequest<PagedListModel<CountryModel>>(
      'countries/filter',
      request
    );
  }

  getById(request: CountriesGetRequest) {
    return this.sendGetRequest<CountryModel>('countries', request);
  }

  getSelectList() {
    return this.sendGetRequest<SimpleItemModel[]>('countries/select-list');
  }

  insert(request: CountriesInsertRequest) {
    return this.sendPostRequest<CountryModel>('countries', request);
  }

  update(request: CountriesUpdateRequest) {
    return this.sendPutRequest('countries', request);
  }

  delete(request: CountriesDeleteRequest): Observable<void> {
    return this.sendDeleteRequest('countries', request);
  }
}
