import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    ChatsGetByIdRequest,
    ChatsGetContactsRequest,
    ChatsGetMessagesRequest,
    ChatsGetModelsRequest,
    ChatsGetRequest,
    ChatsInsertGroupRequest,
    ChatsInsertRequest,
    GetByIdRequest,
} from 'app/models/requests/all';
import { ApiService, StorageService } from './all';

@Injectable({
    providedIn: 'root',
})
export class ChatApiService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: ChatsGetRequest) {
        return this.sendGetRequest('chat/getchats', request);
    }

    getById(request: ChatsGetByIdRequest) {
        return this.sendGetRequest('chat', request);
    }

    getModels(request: ChatsGetModelsRequest) {
        return this.sendGetRequest('chat/getchatmodels', request);
    }

    getContacts(request: ChatsGetContactsRequest) {
        return this.sendGetRequest('chat/getcontacts', request);
    }

    getMessages(request: ChatsGetMessagesRequest) {
        return this.sendGetRequest('chat/getmessages', request);
    }

    getQuickChat() {
        return this.sendGetRequest('chat/getquickchat');
    }

    insert(request: ChatsInsertRequest) {
        return this.sendPostRequest('chat', request);
    }

    insertNewGroup(request: ChatsInsertGroupRequest) {
        return this.sendPostRequest<any>('chat/newchatgroup', request);
    }

    delete(request: GetByIdRequest) {
        return this.sendDeleteRequest<void>('chat', request);
    }
}
