import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import {
    InstitutionActivitiesGetByInstitutionIdRequest,
    InstitutionActivitiesInsertRequest,
    InstitutionActivitiesUpdateRequest,
} from 'app/models/requests/institution-activities.request';
import { InstitutionActivityModel } from 'app/models/institution-activity.model';
import { InstitutionDocumentsDeleteRequest } from 'app/models/requests/institution-document.request';

@Injectable({
    providedIn: 'root',
})
export class InstitutionActivitiesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getByInstitutionId(
        request: InstitutionActivitiesGetByInstitutionIdRequest
    ) {
        return this.sendGetRequest<InstitutionActivityModel[]>(
            'institutionweeklyactivitytypes/getbyinstitutionid',
            request
        );
    }

    insert(request: InstitutionActivitiesInsertRequest) {
        return this.sendPostRequest<InstitutionActivityModel>(
            'institutionweeklyactivitytypes',
            request
        );
    }

    update(request: InstitutionActivitiesUpdateRequest) {
        return this.sendPutRequest<InstitutionActivityModel>(
            'institutionweeklyactivitytypes',
            request
        );
    }

    delete(request: InstitutionDocumentsDeleteRequest) {
        return this.sendDeleteRequest(
            'institutionweeklyactivitytypes',
            request
        );
    }
}
