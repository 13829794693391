import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserComponent } from 'app/layout/common/user/user.component';
import { SharedModule } from 'app/shared/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MY_FORMATS } from 'app/constants/date-format';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

@NgModule({
    declarations: [UserComponent],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        MatDialogModule,
        MatSelectModule,
        TranslateModule.forChild({
            extend: true,
        }),
    ],
    exports: [UserComponent],
    providers: [
        provideMomentDateAdapter( MY_FORMATS, { useUtc: true }),
    ]
})
export class UserModule {}
