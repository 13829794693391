<div
    class="inset-0 sm:static sm:inset-auto flex flex-col -m-6 sm:rounded-2xl overflow-hidden shadow-lg"
>
    <!-- Header -->
    <div
        class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary"
    >
        <div class="text-xl font-medium leading-10">
            {{ "Notifications" | translate }}
        </div>
        <div class="ml-auto">
            <button mat-icon-button (click)="closeDialog()">
                <mat-icon class="icon-size-5 text-current">close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Content -->
    <div
        class="relative flex flex-col flex-auto divide-y overflow-y-auto bg-card"
    >
        <!-- Notifications -->
        <ng-container
            *ngFor="let notification of notifications; trackBy: trackByFn"
        >
            <div
                class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                [ngClass]="{ unread: !notification.readed }"
            >
                <!-- Notification with a link -->
                <ng-container *ngIf="notification.url">
                    <!-- Normal links -->
                    <ng-container *ngIf="!notification.useRouter">
                        <a
                            class="flex flex-auto py-5 pl-6 cursor-pointer"
                            [href]="notification.url"
                        >
                            <ng-container
                                *ngTemplateOutlet="notificationContent"
                            ></ng-container>
                        </a>
                    </ng-container>
                    <!-- Router links -->
                    <ng-container *ngIf="notification.useRouter">
                        <a
                            class="flex flex-auto py-5 pl-6 cursor-pointer"
                            [routerLink]="notification.url"
                        >
                            <ng-container
                                *ngTemplateOutlet="notificationContent"
                            ></ng-container>
                        </a>
                    </ng-container>
                </ng-container>

                <!-- Notification without a link -->
                <ng-container *ngIf="!notification.url">
                    <div class="flex flex-auto py-5 pl-6">
                        <ng-container
                            *ngTemplateOutlet="notificationContent"
                        ></ng-container>
                    </div>
                </ng-container>             
            </div>

            <!-- Notification content template -->
            <ng-template #notificationContent>
                <!-- Icon -->
                <ng-container *ngIf="notification.icon && !notification.image">
                    <div
                        class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700"
                    >
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="notification.icon"
                        >
                        </mat-icon>
                    </div>
                </ng-container>
                <!-- Image -->
                <ng-container>
                    <img
                        class="shrink-0 w-8 mt-4 h-8 mr-4 rounded overflow-hidden object-cover object-center"
                        [src]="
                            getNotificationIcon(notification.notificationType)
                        "
                        [alt]="'Notification image'"
                    />
                </ng-container>
                <!-- Title, description & time -->
                <div class="flex flex-col flex-auto">
                    <ng-container *ngIf="notification.title">
                        <div
                            class="font-semibold line-clamp-1"
                            [innerHTML]="notification.title"
                        ></div>
                    </ng-container>
                    <ng-container *ngIf="notification.body">
                        <div
                            class="line-clamp-2"
                            [innerHTML]="notification.body"
                        ></div>
                    </ng-container>
                    <div class="mt-2 text-sm leading-none text-secondary">
                        {{ notification.createdAt | dateTimeFormat }}
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <!-- No notifications -->
        <ng-container *ngIf="!notifications || !notifications.length">
            <div
                class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
            >
                <div
                    class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600"
                >
                    <mat-icon
                        class="text-primary-700 dark:text-primary-50"
                        [svgIcon]="'heroicons_outline:bell'"
                    ></mat-icon>
                </div>
                <div class="mt-5 text-2xl font-semibold tracking-tight">
                    {{ "NoNotifications" | translate }}
                </div>
                <div
                    class="w-full max-w-60 mt-1 text-md text-center text-secondary"
                >
                    {{
                        "WhenYouHaveNotificationsTheyWillAppearHere" | translate
                    }}
                </div>
            </div>
        </ng-container>
    </div>
</div>
