import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    SimpleIdRequest
} from 'app/models/requests/all';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class FileStorageService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    insert(request: FormData): Observable<any> {
        return this.httpClient.post<any>(
            this.withBaseApiUrl('filestorage/upload'),
            request,
            {
                headers: this.prepareUploadHeaders(),
            }
        );
    }

    insertMultiple(request: FormData): Observable<any> {
        return this.httpClient.post<any>(
            this.withBaseApiUrl('filestorage/upload-multiple'),
            request,
            {
                headers: this.prepareUploadHeaders(),
            }
        );
    }

    delete(request: SimpleIdRequest): Observable<void> {
        return this.sendDeleteRequest('filestorage', request);
    }

    getBlob(request: number) {
        return this.httpClient
            .get(this.withBaseApiUrl('filestorage/getblob?id='+request), {responseType: 'blob'});
    }
}
