import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { PlaceholderChangeInsertRequest } from 'app/models/requests/all';

@Injectable({
    providedIn: 'root',
})
export class PlaceholderChangeService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    insert(request: PlaceholderChangeInsertRequest) {
        return this.sendPostRequest<any>('placeholderrequestchanges', request);
    }
}
