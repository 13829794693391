import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { NonAuthStateModel, SignUpInsertAddress } from './non-auth.actions';
import { NonAuthService } from 'app/services/non-auth.service';
import { tap } from 'rxjs';

@State<NonAuthStateModel>({
    name: 'nonauth',
    defaults: {
        lastAddressId: null,
    },
})
@Injectable()
export class NonAuthState {
    constructor(private _service: NonAuthService) {}

    @Selector()
    static lastAddressId(state: NonAuthStateModel): number | null {
        return state.lastAddressId;
    }

    @Action(SignUpInsertAddress)
    get(context: StateContext<NonAuthStateModel>, params: SignUpInsertAddress) {
        return this._service.insertAddress(params.request).pipe(
            tap((result: any) => {
                context.patchState({
                    lastAddressId: result.id,
                });
            })
        );
    }
}
