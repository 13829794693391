import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { dateFormat, dateTimeFormat, timeFormat } from 'app/constants/date-format';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(value, dateFormat);
    }
}

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(value, dateTimeFormat);
    }
}

@Pipe({
    name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(value, timeFormat);
    }
}

