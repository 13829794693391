import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import { AddressModel, SimpleItemModel } from '../models/all';
import {
    AddressesGetRequest,
    AddressesInsertRequest,
} from '../models/requests/all';
@Injectable({
    providedIn: 'root',
})
export class AddressesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: AddressesGetRequest) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'addresses/select-list',
            request
        );
    }

    insert(request: AddressesInsertRequest) {
        return this.sendPostRequest<AddressModel>('addresses', request);
    }
}
