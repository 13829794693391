import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EventFileModel } from 'app/models/event-file.model';
import {
    EventAttachmentsGetFiles,
    EventFilesDeleteRequest,
    EventFilesInsertRequest,
} from 'app/models/requests/event-files.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class EventFilesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getEventAttachments(request: EventAttachmentsGetFiles) {
        return this.sendGetRequest<any>(
            'eventfiles/geteventattachments',
            request
        );
    }

    insert(request: EventFilesInsertRequest) {
        return this.sendPostRequest<EventFileModel>(
            'eventfiles',
            request
        ).subscribe();
    }

    delete(request: EventFilesDeleteRequest) {
        return this.sendDeleteRequest('eventfiles', request).subscribe();
    }
}
