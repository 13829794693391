import { PreschoolDocumentTypeModel, PagedListModel } from 'app/models/all';
import {
    PreschoolDocumentTypeGetRequest,
    PreschoolDocumentTypeInsertRequest,
    PreschoolDocumentTypeUpdateRequest,
    PreschoolDocumentTypeDeleteRequest,
} from 'app/models/requests/all';

export class PreschoolDocumentTypesStateModel {
    preschoolDocuments?: PagedListModel<PreschoolDocumentTypeModel>;
    filter?: PreschoolDocumentTypeGetRequest;
}

export class PreschoolDocumentTypesGet {
    static readonly type = '[PreschoolDocumentTypes] Get';
    constructor(public request?: PreschoolDocumentTypeGetRequest) {}
}

export class PreschoolDocumentTypesInsert {
    static readonly type = '[PreschoolDocumentTypes] Insert';
    constructor(public request: PreschoolDocumentTypeInsertRequest) {}
}

export class PreschoolDocumentTypesUpdate {
    static readonly type = '[PreschoolDocumentTypes] Update';
    constructor(public request: PreschoolDocumentTypeUpdateRequest) {}
}

export class PreschoolDocumentTypesDelete {
    static readonly type = '[PreschoolDocumentTypes] Delete';
    constructor(public request: PreschoolDocumentTypeDeleteRequest) {}
}
