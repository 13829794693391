import { CountryModel, PagedListModel } from 'app/models/all';
import {
    CountriesGetRequest,
    CountriesInsertRequest,
    CountriesUpdateRequest,
    CountriesDeleteRequest,
} from 'app/models/requests/all';

export class CountriesStateModel {
    countries?: PagedListModel<CountryModel>;
    filter?: CountriesGetRequest;
}

export class CountriesGet {
    static readonly type = '[Countries] Get';
    constructor(public request?: CountriesGetRequest) {}
}

export class CountriesInsert {
    static readonly type = '[Countries] Insert';
    constructor(public request: CountriesInsertRequest) {}
}

export class CountriesUpdate {
    static readonly type = '[Countries] Update';
    constructor(public request: CountriesUpdateRequest) {}
}

export class CountriesDelete {
    static readonly type = '[Countries] Delete';
    constructor(public request: CountriesDeleteRequest) {}
}
