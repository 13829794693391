import { SimpleItemModel } from 'app/models/simple-item.model';

export class SchoolYearsStateModel {
    schoolYears?: SimpleItemModel[];
}

export class SchoolYearsGet {
    static readonly type = '[SchoolYears] Get';
    constructor() {}
}
