import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SimpleItemModel } from 'app/models/simple-item.model';
import { PreschoolGroupsService } from 'app/services/preschool-groups.service';
import { tap } from 'rxjs';
import { SchoolYearsGet, SchoolYearsStateModel } from './school-years.actions';

@State<SchoolYearsStateModel>({
    name: 'schoolYears',
})
@Injectable()
export class SchoolYearsState {
    constructor(private preschoolGroupsService: PreschoolGroupsService) {}

    @Selector()
    static schoolYears(
        state: SchoolYearsStateModel
    ): SimpleItemModel[] | undefined {
        return state.schoolYears;
    }

    @Action(SchoolYearsGet)
    get(context: StateContext<SchoolYearsStateModel>, params: SchoolYearsGet) {
        return this.preschoolGroupsService.getSchoolYears().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    schoolYears: result,
                });
            })
        );
    }
}
