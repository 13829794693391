import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from 'app/core/auth/auth.service';
import { RefreshTokenResponse } from 'app/models/refresh-token.response';
import { UserModel } from 'app/models/user.model';
import { AuthSetNewToken, AuthState } from 'app/stores/all';
import { environment } from 'environments/environment';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Observable, catchError, finalize, forkJoin, map, of, shareReplay, switchMap, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TokenService {

    private refreshTokenObservable$: Observable<any> | null = null;
    
    apiUrl = environment.baseApiUrl;
    constructor(private _store: Store,
        private _authService: AuthService,
        private _httpClient: HttpClient,
        )
        {
        }

    clearTokens(): Observable<null>{
        return this._authService.clearRefreshToken();
    }

    refreshToken(): Observable<any> {
        if (this.refreshTokenObservable$) {
            return this.refreshTokenObservable$;
        }
          const user = this._store.selectSnapshot(AuthState.details);
          this.refreshTokenObservable$ = 
            this._httpClient
                .post<RefreshTokenResponse>(`${environment.baseApiUrl}/access/refresh-token`, {
                    username: user.Email,
                    refreshToken: this._authService.getRefreshToken()
                })
                .pipe(
                    switchMap(refreshResponse => {
                        return forkJoin([
                            this._store.dispatch(new AuthSetNewToken(refreshResponse.token)),
                            this._authService.setRefreshToken(refreshResponse.refreshToken)
                            ]).pipe(
                            // After both operations are done, return true
                            map(() => true)
                        );
                    }),
                    catchError(_ => this.clearTokens()),
                    finalize(() => this.refreshTokenObservable$ = null), // This is the finalize operator
                    shareReplay(1)
                );

          return this.refreshTokenObservable$;
        }
        // const refreshToken = this._authService.getRefreshToken();
        // const email = this._store.selectSnapshot(AuthState.details).Email;
        // return this._httpClient.post(this.apiUrl + '/access/refresh-token', { refreshToken: refreshToken, username: email }).toPromise();

    public isAuthenticatedOrRefresh(): Observable<boolean | null> {
        return this._getToken().pipe(
            switchMap(token => {
                if (token && this.isExpired(token)) {
                    return this.refreshToken().pipe(
                        switchMap(res => {
                            if (res) {
                                return this._isAuthenticated();
                            } else {
                                return of(false);
                            }
                        }),
                        catchError(_ => this.clearTokens())
                    )
                } else {
                    return of(!this.isExpired(token));
                }
            })
        );
    }

    clearRefreshTokens() : Observable<null>{
        this._authService.clearRefreshToken();
        return;
    }


    decode<T>(token: string): T {
        const decodedObject: any = jwtDecode(token);
        let newObject: any = {};

        for (let property in decodedObject) {
            let value = decodedObject[property];
            if (typeof value == 'string') {
                try {
                    value = JSON.parse(value);
                } catch (e) {}
            }

            newObject = { ...newObject, [property]: value };
        }

        return newObject;
    }

    private _getToken(): Observable<string> {
        const token = this._store.selectSnapshot(AuthState.token);
        return of(token ?? null);
    }

    private _isAuthenticated(): Observable<boolean> {
        return this._getToken()
            .pipe(
                map((token: string) => !this.isExpired(token))
            );
    }

    isExpired(token: string): boolean {
        if(token == null)
            return true;
        
        const payload = jwtDecode<JwtPayload>(token);
        if (!payload.exp) return true;
        return Math.floor(new Date().getTime() / 1000) >= payload.exp!;
    }
}
