import { EventTypeModel } from 'app/models/event-type.model';
import {
    EventTypeDeleteRequest,
    EventTypeInsertRequest,
    EventTypeUpdateRequest,
} from 'app/models/requests/all';

export class EventTypesStateModel {
    eventTypes?: EventTypeModel[];
}
export class EventTypesGet {
    static readonly type = '[Event Types] Get All';
    constructor() {}
}

export class EventTypesInsert {
    static readonly type = '[Event Types] Insert';
    constructor(public request: EventTypeInsertRequest) {}
}

export class EventTypesUpdate {
    static readonly type = '[Event Types] Update';
    constructor(public request: EventTypeUpdateRequest) {}
}

export class EventTypesDelete {
    static readonly type = '[Event Types] Delete';
    constructor(public request: EventTypeDeleteRequest) {}
}
