<div class="absolute inset-0">
    <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10">
        <div class="w-full max-w-3xl">
            <img src="assets/images/ui/error401.png" />
        </div>
        <div
            class="mt-16 sm:mt-1 text-xl md:text-5xl font-extrabold tracking-tight leading-tight md:leading-none text-center"
        >{{ 'Unauthorized' | translate }}</div>
        <div
            class="mt-2 text-lg md:text-xl font-medium tracking-tight text-center text-secondary"
        >{{ 'Return' | translate }} <a (click)="goBack()" class="color-primary cursor-pointer hover:underline">{{ 'Back' | translate | lowercase }}</a></div>
    </div>
</div>