import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    DocumentTypesStateModel,
    DocumentTypesGet,
    DocumentTypesInsert,
    DocumentTypesUpdate,
    DocumentTypesDelete,
} from './document-types.actions';
import { DocumentTypeModel, PagedListModel } from 'app/models/all';
import { DocumentTypesService } from 'app/services/all';

@State<DocumentTypesStateModel>({
    name: 'documents',
    defaults: {},
})
@Injectable()
export class DocumentTypesState {
    constructor(private documentTypesService: DocumentTypesService) {}

    @Selector()
    static documents(
        state: DocumentTypesStateModel
    ): DocumentTypeModel[] | undefined {
        return state.documents.items;
    }

    @Action(DocumentTypesGet)
    get(
        context: StateContext<DocumentTypesStateModel>,
        params: DocumentTypesGet
    ) {
        return this.documentTypesService.get(params.request).pipe(
            tap((result: PagedListModel<DocumentTypeModel>) => {
                context.patchState({
                    documents: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(DocumentTypesInsert)
    insert(
        context: StateContext<DocumentTypesStateModel>,
        params: DocumentTypesInsert
    ) {
        return this.documentTypesService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new DocumentTypesGet(previousState.filter));
            })
        );
    }

    @Action(DocumentTypesUpdate)
    update(
        context: StateContext<DocumentTypesStateModel>,
        params: DocumentTypesUpdate
    ) {
        return this.documentTypesService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();
                context.dispatch(new DocumentTypesGet(previousState.filter));
            })
        );
    }

    @Action(DocumentTypesDelete)
    delete(
        context: StateContext<DocumentTypesStateModel>,
        params: DocumentTypesDelete
    ) {
        return this.documentTypesService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new DocumentTypesGet(previousState.filter));
            })
        );
    }
}
