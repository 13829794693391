import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
    AuthModel,
    SimpleItemModel,
    ForgotPasswordModel,
    ChangePasswordModel,
} from '../models/all';
import {
    AuthSignInRequest,
    AuthSignUpRequest,
    AuthForgotPasswordRequest,
    AuthChangePasswordRequest,
} from '../models/requests/all';
import { ApiService, StorageService } from './all';
import { ActiveRoleState, AuthState } from '../stores/all';
import { CookieService } from 'ngx-cookie-service';
import { RefreshTokenRequest } from 'app/models/refresh-token.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends ApiService {
    api = environment.baseApiUrl;
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService,
        private _cookiesService: CookieService
    ) {
        super(store, httpClient, storageService);
    }

    signIn(request: AuthSignInRequest): Observable<AuthModel> {
        return this.sendPostRequest<any>('access/signin', request);
    }

    signOut(request: RefreshTokenRequest): Observable<void> {
        return this.httpClient.post<any>(this.api + '/access/signout', request);
    }

    signUp(request: AuthSignUpRequest): Observable<void> {
        return this.sendPostRequest<void>('access/signup', request);
    }

    forgotPassword(
        request: AuthForgotPasswordRequest
    ): Observable<ForgotPasswordModel> {
        return this.sendPostRequest<ForgotPasswordModel>(
            'access/forgot-password',
            request
        );
    }

    changePassword(
        request: AuthChangePasswordRequest
    ): Observable<ChangePasswordModel> {
        return this.sendPostRequest<ChangePasswordModel>(
            'access/change-password',
            request
        );
    }

    hasRoles(roles: string[]): boolean {
        try {
            const details = this.store.selectSnapshot(AuthState.details);
            if (!details) {
                return false;
            }
            return details.Roles.some((ur: SimpleItemModel) =>
                roles.some((r: string) => ur.label == r)
            );
        } catch (e) {
            return false;
        }
    }

    activeRole(role: SimpleItemModel): boolean {
        const activeRole = this.store.selectSnapshot(
            ActiveRoleState.activeRole
        );

        if (!activeRole) {
            return false;
        }
        return activeRole.label === role.label;
    }

    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this.httpClient.post('api/auth/unlock-session', credentials);
    }

    setRefreshToken(token: string) {
        this.clearRefreshToken();
        const expires = new Date();
        // Set cookie to expire in 30 days (adjust as necessary)
        expires.setDate(expires.getDate() + 1);
      
        this._cookiesService.set('refreshToken', token, {
          expires,
          path: '/',
          secure: true, // Ensure the cookie is only sent over HTTPS
          sameSite: 'Strict', // Helps prevent CSRF attacks
          // Note: `HttpOnly` is a server-side setting and cannot be set directly via JavaScript.
          //       The server should ensure this attribute is set when sending the cookie.
        });
      }

      getRefreshToken(): string | null {
        return this._cookiesService.get('refreshToken');
      }

      clearRefreshToken() {
        this._cookiesService.delete('refreshToken', '/');
      }

}
