import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { activeRole } from 'app/constants/constants';
import { StorageService } from 'app/services/storage.service';
import { TokenService } from 'app/services/token.service';

@Injectable({
    providedIn: 'root',
})
export class LayoutGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
        private tokenService: TokenService,
        private storageService: StorageService
    ) {}
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const activeRoleItem = this.storageService.getItem(activeRole);
        if (activeRoleItem == 'parent') {
            route.data.layout = 'centered';
        }
        return true;
    }
}
