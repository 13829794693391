import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { DetailsComponent } from 'app/modules/landing/details/details.component';
import { UnSub } from 'app/shared/extensions/unSub';
import { CalendarTypeEnum } from 'app/models/enums/calendar-type.enum';

@Component({
    selector: 'app-event-info',
    templateUrl: './event-info.component.html',
    styleUrls: ['./event-info.component.scss'],
})
export class EventInfoComponent extends UnSub {
    constructor(
        public dialogRef: MatDialogRef<EventInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public model: any,
        public _dialog: MatDialog
    ) {
        super();
    }
    weeklyActivityEnum = CalendarTypeEnum[CalendarTypeEnum.WeeklyActivity];
    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    openDetails(files: string[], currentPicture: string) {
        this._dialog.open(DetailsComponent, {
            data: { files: files, currentPicture: currentPicture },
            disableClose: false,
            maxWidth: '70vh',
            maxHeight: '100vh',
            width: '95%',
        });
    }
}
