import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivityTypeModel } from 'app/models/activity-type.model';
import {
    ActivityTypesDeleteRequest,
    ActivityTypesInsertRequest,
    ActivityTypesUpdateRequest,
} from 'app/models/requests/activity-type.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityTypesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get() {
        return this.sendGetRequest<ActivityTypeModel[]>(
            'weeklyactivitytypes/getall'
        );
    }

    insert(request: ActivityTypesInsertRequest) {
        return this.sendPostRequest<ActivityTypeModel>(
            'weeklyactivitytypes',
            request
        );
    }

    update(request: ActivityTypesUpdateRequest) {
        return this.sendPutRequest<ActivityTypeModel>(
            'weeklyactivitytypes',
            request
        );
    }

    delete(request: ActivityTypesDeleteRequest) {
        return this.sendDeleteRequest<any>('weeklyactivitytypes', request);
    }
}
