import {
    AuthSignInRequest,
    AuthSignUpRequest,
    AuthForgotPasswordRequest,
    AuthChangePasswordRequest,
} from 'app/models/requests/all';
import { UserModel } from 'app/models/all';
import { Injectable } from '@angular/core';

export class AuthStateModel {
    token: string | undefined;
    details: UserModel | undefined;
}

export class AuthSignIn {
    static readonly type = '[Auth] SignIn';
    constructor(public request: AuthSignInRequest) {}
}

@Injectable({providedIn: 'root'})
export class AuthRefreshToken {
    static readonly type = '[Auth] RefreshToken';
    constructor() {}
}

export class AuthSignOut {
    static readonly type = '[Auth] SignOut';
    constructor() {}
}

export class AuthSignUp {
    static readonly type = '[Auth] SignUp';
    constructor(public request: AuthSignUpRequest) {}
}

export class AuthForgotPassword {
    static readonly type = '[Auth] ForgotPassword';
    constructor(public request: AuthForgotPasswordRequest) {}
}

export class AuthChangePassword {
    static readonly type = '[Auth] ChangePassword';
    constructor(public request: AuthChangePasswordRequest) {}
}

export class AuthRefresh {
    static readonly type = '[Auth] Refresh';
    constructor(public updated: UserModel) {}
}

export class AuthReset {
    static readonly type = '[Auth] Reset';
    constructor() {}
}

export class AuthSetNewToken {
    static readonly type = '[Auth] SetNewToken';
    constructor(public token: string) {}
}

export class AuthProfilePhotoRefresh {
    static readonly type = '[Auth] ProfilePhotoRefresh';
    constructor(
        public updated: UserModel,
        public url: string,
        public thumbnailUrl: string
    ) {}
}
