import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';

import {
    EnumsStateModel,
    GetAnnouncements,
    GetGenders,
    GetParentshipTypes,
    GetQualifications,
    GetVisibilities,
} from './enums.actions';
import { SimpleItemModel } from '../../models/all';
import { EnumsService } from 'app/services/all';

@State<EnumsStateModel>({
    name: 'enums',
    defaults: {},
})
@Injectable()
export class EnumsState {
    constructor(private enumsService: EnumsService) {}

    @Action(GetGenders)
    getGenders(context: StateContext<EnumsStateModel>, params: GetGenders) {
        return this.enumsService.getGenders().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    simpleItemGenders: result,
                });
            })
        );
    }

    @Action(GetQualifications)
    getQualifications(
        context: StateContext<EnumsStateModel>,
        params: GetQualifications
    ) {
        return this.enumsService.getQualifications().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    simpleItemQualifications: result,
                });
            })
        );
    }

    @Action(GetParentshipTypes)
    getParentshipTypes(
        context: StateContext<EnumsStateModel>,
        params: GetParentshipTypes
    ) {
        return this.enumsService.getParentshipTypes().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    simpleItemParentshipTypes: result,
                });
            })
        );
    }

    @Action(GetVisibilities)
    getVisibilities(
        context: StateContext<EnumsStateModel>,
        params: GetVisibilities
    ) {
        return this.enumsService.getVisibilities().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    simpleItemVisibilities: result,
                });
            })
        );
    }

    @Action(GetAnnouncements)
    getAnnouncements(
        context: StateContext<EnumsStateModel>,
        params: GetAnnouncements
    ) {
        return this.enumsService.getAnnouncements().pipe(
            tap((result: SimpleItemModel[]) => {
                context.patchState({
                    simpleItemAnnouncements: result,
                });
            })
        );
    }
}
