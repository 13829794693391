import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotificationModel, PagedListModel } from 'app/models/all';
import {
    NotificationsDeleteRequest,
    NotificationsGetRequest,
    NotificationsInsertRequest,
    NotificationsMarkAllAsReadRequest,
    NotificationsMarkAsReadRequest,
} from 'app/models/requests/all';
import { take, takeUntil } from 'rxjs';
import { ApiService, StorageService } from './all';

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: NotificationsGetRequest) {
        return this.sendGetRequest<PagedListModel<NotificationModel>>(
            'notifications/filter',
            request
        );
    }

    insert(request: NotificationsInsertRequest) {
        return this.sendPostRequest<any>('notifications', request);
    }

    markAsRead(request: NotificationsMarkAsReadRequest) {
        return this.sendPutRequest<void>('notifications/markasread', request)
          
    }

    markAllAsRead(request: NotificationsMarkAllAsReadRequest) {
        return this.sendPutRequest<void>('notifications/markallasread', request)
    }

    remove(request: NotificationsDeleteRequest) {
       return this.sendDeleteRequest('notifications/remove', request);
    }
}
