import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EvaluationItemModel } from 'app/models/evaluation-items.model';
import { EvaluationItemsService } from 'app/services/evaluation-items.service';
import { tap } from 'rxjs';
import {
    EvaluationItemsGet,
    EvaluationItemsStateModel,
} from './evaluation-items.actions';

@State<EvaluationItemsStateModel>({
    name: 'evaluationItems',
    defaults: {},
})
@Injectable()
export class EvaluationItemsState {
    constructor(private service: EvaluationItemsService) {}

    @Selector()
    static evaluationItems(
        state: EvaluationItemsStateModel
    ): EvaluationItemModel[] | undefined {
        return state.evaluationItems;
    }

    @Action(EvaluationItemsGet)
    get(
        context: StateContext<EvaluationItemsStateModel>,
        params: EvaluationItemsGet
    ) {
        return this.service.getAll().pipe(
            tap((result: EvaluationItemModel[]) => {
                context.patchState({
                    evaluationItems: result,
                });
            })
        );
    }
}
