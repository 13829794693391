import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnrollmentRequestLogModel, PagedListModel } from 'app/models/all';
import { EnrollmentModel } from 'app/models/enrollment.model';
import {
    EnrollmentLogsInsertRequest,
    EnrollmentRequestGetDataById,
    EnrollmentsDeleteRequest,
    EnrollmentsGetByChildId,
    EnrollmentsGetByParentIdRequest,
    EnrollmentsGetRequest,
    EnrollmentsInsertRequest,
    GetByIdRequest,
} from 'app/models/requests/all';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class EnrollmentsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getDataById(request:EnrollmentRequestGetDataById){
        return this.sendGetRequest<any>('enrollments/getbyenrollmentinfo', request);
    }
    get(request: EnrollmentsGetRequest) {
        return this.sendGetRequest<PagedListModel<EnrollmentModel>>(
            'enrollments/filter',
            request
        );
    }

    getByChildId(request: EnrollmentsGetByChildId) {
        return this.sendGetRequest<EnrollmentModel[]>(
            'enrollments/getbychildid',
            request
        );
    }

    getLatestByChildId(childId: number){
        return this.sendGetRequest<EnrollmentModel>(`enrollments/latestenrollment?childId=${childId}`);
    }

    getMessage(request: GetByIdRequest): Observable<any> {
        return this.sendGetRequest<any>(
            'enrollments/getenrollmentstatusesbyparentid',
            request
        );
    }

    insert(request: EnrollmentsInsertRequest) {
        const params: any = request?.toParams();
        const body: any = request?.toBody();
        return this.httpClient.post<any>(
            this.withBaseApiUrl('enrollments'),
            body,
            {
                headers: this.prepareHeaders(),
                params: params,
            }
        );
    }

    renew(request: EnrollmentsInsertRequest){
       return this.sendPostRequest<{ message : string}>('enrollments/renew', request)
    }

    insertRequestLog(request: EnrollmentLogsInsertRequest) {
        return this.sendPostRequest<EnrollmentRequestLogModel>(
            'enrollments/insertenrollmentrequestlog',
            request
        );
    }

    delete(request: EnrollmentsDeleteRequest) {
        return this.sendDeleteRequest('enrollments', request);
    }

    getByParentId(request: EnrollmentsGetByParentIdRequest) {
        return this.sendGetRequest<EnrollmentModel[]>(
            'enrollments/getbyparentid',
            request
        );
    }
}
