import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { DocumentTypeModel, PagedListModel } from '../models/all';
import { ApiService, StorageService } from './all';
import {
    DocumentTypeGetRequest,
    DocumentTypeInsertRequest,
    DocumentTypeUpdateRequest,
    DocumentTypeDeleteRequest,
    DocumentTypesForEnrollmentRequest,
} from '../models/requests/all';

@Injectable({ providedIn: 'root' })
export class DocumentTypesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: DocumentTypeGetRequest) {
        return this.sendGetRequest<PagedListModel<DocumentTypeModel>>(
            'documenttypes/filter',
            request
        );
    }

    
    getForEnrollment(request: DocumentTypesForEnrollmentRequest) {
        return this.sendGetRequest<DocumentTypeModel[]>(
            'documenttypes/getdocumentsforenrollment',
            request
        );
    }

    insert(request: DocumentTypeInsertRequest) {
        return this.sendPostRequest<DocumentTypeModel>(
            'documenttypes',
            request
        );
    }

    update(request: DocumentTypeUpdateRequest) {
        return this.sendPutRequest('documenttypes', request);
    }

    delete(request: DocumentTypeDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('documenttypes', request);
    }
}
