import {
    ChatsGetContactsRequest,
    ChatsGetMessagesRequest,
    ChatsGetRequest,
} from 'app/models/requests/chat.request';

export class ChatStateModel {
    chats?: any[];
    contacts?: any[];
    messages?: any[];
}

export class ChatsGet {
    static readonly type = '[Chat] Get';
    constructor(public request?: ChatsGetRequest) {}
}

export class ChatsGetMessages {
    static readonly type = '[Chat] Get Messages';
    constructor(public request?: ChatsGetMessagesRequest) {}
}

export class ChatsGetContacts {
    static readonly type = '[Chat] Get Contacts';
    constructor(public request: ChatsGetContactsRequest) {}
}
