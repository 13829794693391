import { ActivityTypeModel } from 'app/models/activity-type.model';
import {
    ActivitiesDelete,
    ActivitiesGet,
    ActivitiesInsert,
    ActivitiesStateModel,
    ActivitiesUpdate,
} from './activity-types.actions';
import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { ActivityTypesService } from 'app/services/activity-types.service';
import { tap } from 'rxjs';

@State<ActivitiesStateModel>({
    name: 'activities',
    defaults: {},
})
@Injectable()
export class ActivitiesState {
    constructor(private _service: ActivityTypesService) {}

    @Selector()
    static activities(
        state: ActivitiesStateModel
    ): ActivityTypeModel[] | undefined {
        return state.activities;
    }

    @Action(ActivitiesGet)
    get(context: StateContext<ActivitiesStateModel>) {
        return this._service.get().pipe(
            tap((result: ActivityTypeModel[]) => {
                context.patchState({
                    activities: result,
                });
            })
        );
    }

    @Action(ActivitiesInsert)
    insert(
        context: StateContext<ActivitiesStateModel>,
        params: ActivitiesInsert
    ) {
        return this._service.insert(params.request).pipe(
            tap(() => {
                context.dispatch(new ActivitiesGet());
            })
        );
    }

    @Action(ActivitiesUpdate)
    update(
        context: StateContext<ActivitiesStateModel>,
        params: ActivitiesUpdate
    ) {
        return this._service.update(params.request).pipe(
            tap(() => {
                context.dispatch(new ActivitiesGet());
            })
        );
    }

    @Action(ActivitiesDelete)
    delete(
        context: StateContext<ActivitiesStateModel>,
        params: ActivitiesDelete
    ) {
        return this._service.delete(params.request).pipe(
            tap(() => {
                context.dispatch(new ActivitiesGet());
            })
        );
    }
}
