import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EventTypeModel } from 'app/models/event-type.model';
import { EventTypesService } from 'app/services/event-types.service';
import { tap } from 'rxjs';
import {
    EventTypesDelete,
    EventTypesGet,
    EventTypesInsert,
    EventTypesStateModel,
    EventTypesUpdate,
} from './event-types.actions';

@State<EventTypesStateModel>({
    name: 'eventTypes',
    defaults: {},
})
@Injectable()
export class EventTypesState {
    constructor(private service: EventTypesService) {}

    @Selector()
    static eventTypes(
        state: EventTypesStateModel
    ): EventTypeModel[] | undefined {
        return state.eventTypes;
    }

    @Action(EventTypesGet)
    get(context: StateContext<EventTypesStateModel>, params: EventTypesGet) {
        return this.service.getAll().pipe(
            tap((result: EventTypeModel[]) => {
                context.patchState({
                    eventTypes: result,
                });
            })
        );
    }

    @Action(EventTypesUpdate)
    update(
        context: StateContext<EventTypesStateModel>,
        params: EventTypesUpdate
    ) {
        return this.service.update(params.request).pipe(
            tap(() => {
                context.dispatch(new EventTypesGet());
            })
        );
    }

    @Action(EventTypesInsert)
    insert(
        context: StateContext<EventTypesStateModel>,
        params: EventTypesInsert
    ) {
        return this.service.insert(params.request).pipe(
            tap(() => {
                context.dispatch(new EventTypesGet());
            })
        );
    }

    @Action(EventTypesDelete)
    delete(
        context: StateContext<EventTypesStateModel>,
        params: EventTypesDelete
    ) {
        return this.service.delete(params.request).pipe(
            tap(() => {
                context.dispatch(new EventTypesGet());
            })
        );
    }
}
