import { BaseRequest } from './all';

export class BusinessUnitsGetRequest implements BaseRequest {
    constructor(
        public id?: number | null,
        public name?: string | null,
        public idNumber?: number | null,
        public preschoolInstitutionId?: number | null
    ) {}

    toParams(): Object {
        return {
            ...(this.id && { id: this.id }),
            ...(this.name && { name: this.name }),
            ...(this.idNumber && { idNumber: this.idNumber }),
            ...(this.preschoolInstitutionId && {
                preschoolInstitutionId: this.preschoolInstitutionId,
            }),
        };
    }

    toBody(): Object | null {
        return null;
    }
}

export class BusinessUnitsGetSelectListRequest implements BaseRequest {
    constructor(
        public preschoolInstitutionId?: number,
        public includeAllItem?: boolean
    ) {}

    toParams(): Object {
        return {
            ...(this.preschoolInstitutionId && {
                preschoolInstitutionId: this.preschoolInstitutionId,
            }),
            ...(this.includeAllItem && { includeAllItem: this.includeAllItem }),
        };
    }

    toBody(): Object {
        return null;
    }
}

export class BusinessUnitsInsertRequest implements BaseRequest {
    constructor(
        public id: number,
        public name: string,
        public idNumber: string,
        public email: string,
        public phoneNumber: string | null,
        public addressText: string,
        public preschoolInstitutionId: number,
        public isActive: boolean,
        public identifier: string,
        public programId?: number,
        public logoId?: number,
        public primaryColor?: string | null
    ) {}

    toParams(): Object | null {
        return null;
    }

    toBody(): Object {
        return {
            id: this.id,
            name: this.name,
            idNumber: this.idNumber,
            email: this.email,
            phoneNumber: this.phoneNumber,
            addressText: this.addressText,
            isActive: this.isActive,
            identifier: this.identifier,
            preschoolInstitutionId: this.preschoolInstitutionId,
            programId: this.programId,
            logoId: this.logoId,
            ...(this.primaryColor && {
                PrimaryColor: this.primaryColor,
            }),
        };
    }
}

export class BusinessUnitsUpdateRequest implements BaseRequest {
    constructor(
        public id: number,
        public name: string,
        public idNumber: string,
        public email: string,
        public phoneNumber: string | null,
        public addressText: string,
        public preschoolInstitutionId: number,
        public isActive: boolean,
        public identifier: string,
        public logoId?: number,
        public primaryColor?: string | null
    ) {}

    toParams(): Object | null {
        return null;
    }

    toBody(): Object {
        return {
            id: this.id,
            name: this.name,
            idNumber: this.idNumber,
            email: this.email,
            phoneNumber: this.phoneNumber,
            addressText: this.addressText,
            isActive: this.isActive,
            identifier: this.identifier,
            preschoolInstitutionId: this.preschoolInstitutionId,
            logoId: this.logoId,
            ...(this.primaryColor && {
                PrimaryColor: this.primaryColor,
            }),
        };
    }
}

export class BusinessUnitsDeleteRequest implements BaseRequest {
    constructor(public id: number) {}

    toParams(): Object {
        return {
            id: this.id,
        };
    }

    toBody(): Object | null {
        return null;
    }
}
