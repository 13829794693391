import { NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCommonModule, MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatChipsModule } from "@angular/material/chips";
import { MatLuxonDateModule } from "@angular/material-luxon-adapter";

@NgModule({
    imports: [
        MatTableModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatCardModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatCommonModule,
        MatSortModule,
        MatStepperModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSidenavModule,
        MatBadgeModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatLuxonDateModule,
        MatRippleModule
    ],
    exports: [
       MatTableModule,
       MatInputModule,
       MatIconModule,
       MatSelectModule,
       MatButtonModule,
       MatCardModule,
       MatTooltipModule,
       MatPaginatorModule,
       MatCheckboxModule,
       MatDividerModule,
       MatFormFieldModule,
       MatCommonModule,
       MatSortModule,
       MatStepperModule,
       MatExpansionModule,
       MatDatepickerModule,
       MatNativeDateModule,
       MatTabsModule,
       MatProgressBarModule,
       MatDialogModule,
       MatSidenavModule,
       MatBadgeModule,
       MatRadioModule,
       MatButtonToggleModule,
       MatMenuModule,
       MatSlideToggleModule,
       MatProgressSpinnerModule,
       MatChipsModule,
       MatLuxonDateModule,
       MatRippleModule
    ]
})
export class MatModule {}
