import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ApiService, StorageService } from './all';
import { Injectable } from '@angular/core';
import { HeaderSearchRequest } from 'app/models/requests/all';

@Injectable({ providedIn: 'root' })
export class SearchService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    searchHeader(request: HeaderSearchRequest) {
        return this.sendGetRequest('search/header-app-search', request);
    }
}
