import { PagedListModel, PreschoolInstitutionModel } from 'app/models/all';
import {
    PreschoolInstitutionsGetRequest,
    PreschoolInstitutionsInsertRequest,
    PreschoolInstitutionsUpdateRequest,
    PreschoolInstitutionsDeleteRequest,
    GetByUserIdRequest,
} from 'app/models/requests/all';

export class PreschoolInstitutionsStateModel {
    preschoolInstitutions?: PagedListModel<PreschoolInstitutionModel>;
    filter?: PreschoolInstitutionsGetRequest;
}

export class PreschoolInstitutionsGet {
    static readonly type = '[Preschool Institutions] Get';
    constructor(public request?: PreschoolInstitutionsGetRequest) {}
}

export class PreschoolInstitutionsGetByUserId {
    static readonly type = '[Preschool Institutions] GetByUserId';
    constructor(public request: GetByUserIdRequest) {}
}

export class PreschoolInstitutionsInsert {
    static readonly type = '[Preschool Institutions] Insert';
    constructor(public request: PreschoolInstitutionsInsertRequest) {}
}

export class PreschoolInstitutionsUpdate {
    static readonly type = '[Preschool Institutions] Update';
    constructor(public request: PreschoolInstitutionsUpdateRequest) {}
}

export class PreschoolInstitutionsDelete {
    static readonly type = '[Preschool Institutions] Delete';
    constructor(public request: PreschoolInstitutionsDeleteRequest) {}
}
