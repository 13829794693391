import { BaseRequest } from './base.request';

export class NotificationsGetRequest implements BaseRequest {
    constructor(
        public userId: number,
        public readed: boolean,
        public page: number,
        public pageSize: number
    ) {}
    toParams(): Object {
        return {
            UserId: this.userId,
            ...(this.readed && { Readed: this.readed }),
            ...(this.page && { Page: this.page }),
            ...(this.pageSize && { PageSize: this.pageSize }),
        };
    }
    toBody(): Object {
        throw new Error('Method not implemented.');
    }
}

export class NotificationsInsertRequest implements BaseRequest {
    constructor(
        public id: number | null,
        public title: string,
        public body: string,
        public notificationType: number,
        public idsOfUsers: number[]
    ) {}
    toParams(): Object {
        return {
            ...(this.id && { id: this.id }),
            title: this.title,
            body: this.body,
            notificationType: this.notificationType,
            idsOfUsers: this.idsOfUsers,
            isActive: true,
        };
    }

    toBody(): Object {
        return {
            ...(this.id && { id: this.id }),
            title: this.title,
            body: this.body,
            notificationType: this.notificationType,
            idsOfUsers: this.idsOfUsers,
            isActive: true,
        };
    }
}

export class NotificationsMarkAsReadRequest implements BaseRequest {
    constructor(
        public id: number | null,
        public notificationId: number,
        public userId: number,
        public readed: boolean
    ) {}
    toParams(): Object {
        return {
            ...(this.id && { id: this.id }),
            notificationId: this.notificationId,
            userId: this.userId,
            readed: this.readed,
        };
    }
    toBody(): Object {
        return {
            ...(this.id && { id: this.id }),
            notificationId: this.notificationId,
            userId: this.userId,
            readed: this.readed,
        };
    }
}

export class NotificationsMarkAllAsReadRequest implements BaseRequest {
    constructor(public userId: number) {}
    toParams(): Object {
        return {
            userId: this.userId,
        };
    }
    toBody(): Object {
        return {
            userId: this.userId,
        };
    }
}

export class NotificationsDeleteRequest implements BaseRequest {
    constructor(public userId: number, public notificationId: number) {}
    toParams(): Object {
        return { userId: this.userId, notificationId: this.notificationId };
    }
    toBody(): Object {
        return { userId: this.userId, notificationId: this.notificationId };
    }
}
