import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { DetailsComponent } from 'app/modules/landing/details/details.component';
import { UnSub } from 'app/shared/extensions/unSub';

@Component({
    selector: 'app-announcement-info',
    templateUrl: './announcement-info.component.html',
    styleUrls: ['./announcement-info.component.scss'],
})
export class AnnouncementInfoComponent extends UnSub implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AnnouncementInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public model: any,
        public _dialog: MatDialog
    ) {
        super();
    }
    ngOnInit(): void {}

    closeDialog() {
        this.dialogRef.close();
    }

    getThumbnailOfActivity(type: number): string {
        if (type == 1) return '/assets/img/annoucements/info.png';
        if (type == 2) return '/assets/img/annoucements/calendar.png';
        if (type == 3) return '/assets/img/annoucements/warning.png';
    }

    openDetails(files: string[], currentUrl: string) {
        if (
            !(
                currentUrl.includes('jpg') ||
                currentUrl.includes('jpeg') ||
                currentUrl.includes('png') ||
                currentUrl.includes('jfif') ||
                currentUrl.includes('avif')
            )
        ) {
            window.open(currentUrl, '_blank');
        }

        this._dialog.open(DetailsComponent, {
            data: { files: files, currentPicture: currentUrl },
            disableClose: false,
            maxWidth: '70vh',
            maxHeight: '100vh',
            width: '95%',
        });
    }
}
