import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { ChatApiService } from 'app/services/chat.service';
import { tap } from 'rxjs';
import {
    ChatsGet,
    ChatsGetContacts,
    ChatsGetMessages,
    ChatStateModel,
} from './chat.actions';

@State<ChatStateModel>({
    name: 'chat',
    defaults: {
        chats: undefined,
        contacts: undefined,
        messages: undefined,
    },
})
@Injectable()
export class ChatsState {
    constructor(private service: ChatApiService) {}

    @Selector()
    static chats(state: ChatStateModel): any[] | undefined {
        return state.chats;
    }

    static messages(state: ChatStateModel): any[] | undefined {
        return state.messages;
    }

    static contacts(state: ChatStateModel): any[] | undefined {
        return state.contacts;
    }

    @Action(ChatsGet)
    get(context: StateContext<ChatStateModel>, params: ChatsGet) {
        return this.service.get(params.request).pipe(
            tap((result: any[]) => {
                context.patchState({
                    chats: result,
                });
            })
        );
    }

    @Action(ChatsGetContacts)
    getContacts(
        context: StateContext<ChatStateModel>,
        params: ChatsGetContacts
    ) {
        return this.service.get(params.request).pipe(
            tap((result: any[]) => {
                context.patchState({
                    contacts: result,
                });
            })
        );
    }

    @Action(ChatsGetMessages)
    getMessages(
        context: StateContext<ChatStateModel>,
        params: ChatsGetMessages
    ) {
        return this.service.getMessages(params.request).pipe(
            tap((result: any[]) => {
                context.patchState({
                    messages: result,
                });
            })
        );
    }
}
