import { EvaluationItemModel } from 'app/models/evaluation-items.model';
import {
    EvaluationItemsDeleteRequest,
    EvaluationItemsUpdateRequest,
} from 'app/models/requests/all';

export class EvaluationItemsStateModel {
    evaluationItems?: EvaluationItemModel[];
}
export class EvaluationItemsGet {
    static readonly type = '[Evaluation Items] Get All';
    constructor() {}
}

export class EvaluationItemsInsert {
    static readonly type = '[Evaluation Items] Insert';
    constructor(public request: EvaluationItemsInsert) {}
}

export class EvaluationItemsUpdate {
    static readonly type = '[Evaluation Items] Update';
    constructor(public request: EvaluationItemsUpdateRequest) {}
}

export class EvaluationItemsDelete {
    static readonly type = '[Evaluation Items] Delete';
    constructor(public request: EvaluationItemsDeleteRequest) {}
}
