import { BaseRequest } from './base.request';

export class HeaderSearchRequest implements BaseRequest {
    constructor(
        public input: string,
        public institutionId: number,
        public unitId: number | null
    ) {}
    toParams(): Object {
        return {
            input: this.input,
            ...(this.institutionId && { institutionId: this.institutionId }),
            ...(this.unitId && { unitId: this.unitId }),
        };
    }
    toBody(): Object {
        return {
            input: this.input,
            ...(this.institutionId && { institutionId: this.institutionId }),
            ...(this.unitId && { unitId: this.unitId }),
        };
    }
}
