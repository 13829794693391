import { InstitutionActivityModel } from 'app/models/all';
import { InstitutionActivitiesGetByInstitutionIdRequest } from 'app/models/requests/all';

export class InstitutionActivitiesStateModel {
    institutionActivities?: InstitutionActivityModel[];
}
export class InstitutionActivitiesGetByPreschoolId {
    static readonly type = '[Activities] GetByPreschoolId';
    constructor(
        public request: InstitutionActivitiesGetByInstitutionIdRequest
    ) {}
}
