import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AuthState } from 'app/stores/all';
import { TokenService } from 'app/services/token.service';
import { SnackBarService } from 'app/services/snack-bar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _store: Store,
        public _snackBarService: SnackBarService,
        public _translateService: TranslateService,
        public _tokenService: TokenService
    ) {}

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (
            this._authService.accessToken &&
            !AuthUtils.isTokenExpired(this._authService.accessToken)
        ) {
            newReq = req.clone({
                headers: req.headers.set(
                    'Authorization',
                    'Bearer ' + this._authService.accessToken
                ),
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 404
                ) {
                    this._router.navigate(['/error']);
                }

                if (
                    error.status == 401 &&
                    this._store.selectSnapshot(AuthState.token) &&
                    !this._tokenService.isExpired(
                        String(this._store.selectSnapshot(AuthState.token))
                    )
                ) {
                    this._router.navigate(['/error/403']);
                }

                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 500
                ) {
                    this._router.navigate(['/error/500']);
                }

                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 409
                ) {
                    this._snackBarService.showSnackBar(
                        this._translateService.instant(error.error.message)
                    );
                }

                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401 &&
                    !this._store.selectSnapshot(AuthState.token)
                    )
                {
                    this._router.navigate(["/sign-in"]);
                }

                if (req.url.includes('refresh-token')) {
                    return next.handle(newReq).pipe(
                        catchError((error) => {
                            // Catch "401 Unauthorized" responses
                            if (error instanceof HttpErrorResponse && error.status === 401) {
                                // Sign out
                                this._authService.signOut();
                            }
            
                            return throwError(() => error);
                        })
                    );
                }

                // Catch "401 Unauthorized" responses
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401 &&
                    this._tokenService.isExpired(
                        String(this._store.selectSnapshot(AuthState.token))
                    )
                ) {
                     return this._tokenService
                     .isAuthenticatedOrRefresh().pipe(
                         switchMap(authenticated => {
                             if (authenticated) {
                                const token = this._store.selectSnapshot(AuthState.token);
                                newReq = req.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } });
                                return next.handle(newReq);
                             }
                             return next.handle(newReq).pipe(
                                 catchError((error) => {
                                     // Catch "401 Unauthorized" responses
                                     if (error instanceof HttpErrorResponse && error.status === 401) {
                                         // Sign out
                                         this._authService.signOut();
                                         this._router.navigate(["/sign-in"], { state: { sessionExpired: true }})
             
                                         // Reload the app
                                     }
             
                                     return throwError(error);
                                 })
                             );
                         })
                     );
                }

                return throwError(error);
            })
        );
    }
}
