import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EvaluationItemModel } from 'app/models/evaluation-items.model';
import {
    EvaluationItemsDeleteRequest,
    EvaluationItemsGetRequest,
    EvaluationItemsInsertRequest,
    EvaluationItemsSearchByNameRequest,
    EvaluationItemsUpdateRequest,
} from 'app/models/requests/evaluation-item.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class EvaluationItemsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getAll() {
        return this.sendGetRequest<EvaluationItemModel[]>(
            'evaluationitems/getall'
        );
    }

    get(request: EvaluationItemsGetRequest) {
        return this.sendGetRequest<EvaluationItemModel>(
            'evaluationitems',
            request
        );
    }

    getByName(request: EvaluationItemsSearchByNameRequest) {
        return this.sendGetRequest<EvaluationItemModel[]>(
            'evaluationitems/getbyname',
            request
        );
    }

    insert(request: EvaluationItemsInsertRequest) {
        return this.sendPostRequest<any>('evaluationitems', request);
    }

    update(request: EvaluationItemsUpdateRequest) {
        return this.sendPutRequest<any>('evaluationitems', request);
    }

    delete(request: EvaluationItemsDeleteRequest) {
        return this.sendDeleteRequest('evaluationitems', request);
    }
}
