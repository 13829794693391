import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PagedListModel, PersonModel } from 'app/models/all';
import { EventModel } from 'app/models/event.model';
import {
    EventGetCoordinatorsRequest,
    EventsDeleteRequest,
    EventsGetByBusinessUnitIdsRequest,
    EventsGetPublicRequest,
    EventsGetRequest,
    EventsInsertRequest,
    EventsUpdateRequest,
    GetByIdRequest,
} from 'app/models/requests/all';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class EventsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: EventsGetRequest) {
        return this.sendGetRequest<PagedListModel<EventModel>>(
            'events/filter',
            request
        );
    }

    getPublic(request: EventsGetPublicRequest){
        return this.sendGetRequest<PagedListModel<EventModel>>(
            'events/getpublicevents', request
        )
    }

    getCoordinators(
        request: EventGetCoordinatorsRequest
    ): Observable<PersonModel[]> {
        return this.sendGetRequest('events/getcoordinators', request);
    }

    insert(request: EventsInsertRequest) {
        return this.sendPostRequest<any>('events', request);
    }

    update(request: EventsUpdateRequest) {
        return this.sendPutRequest<any>('events', request);
    }

    delete(request: EventsDeleteRequest) {
        return this.sendDeleteRequest<any>('events', request);
    }

    getById(request: GetByIdRequest){
        return this.sendGetRequest<EventModel>('events', request);
    }
    getByIdForCalendar(request: GetByIdRequest){
        return this.sendGetRequest<EventModel>(
            'events/gebyidforcalendar',
            request
        );
    }

    getByBusinessUnitIds(request: EventsGetByBusinessUnitIdsRequest){
        return this.sendGetRequest<PagedListModel<EventModel>>(
            'events/geteventsbybusinessunitids',
            request
        );
    }
    getEventsAndWeeklyActivities(request: EventsGetByBusinessUnitIdsRequest){
        return this.sendGetRequest<any[]>(
            'events/geteventsandweeklyactivities',
            request
        );
    }
    getPublicEventsAndWeeklyActivities(request: EventsGetPublicRequest){
        return this.sendGetRequest<any[]>(
            'events/getpubliceventsandweeklyactivities',
            request
        );
    }
}
