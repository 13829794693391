import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SimpleItemModel } from 'app/models/simple-item.model';
import { EnumsService } from 'app/services/enums.service';
import { tap } from 'rxjs';
import { ActiveRoleStateModel, ActiveRoleGet } from './active-role.actions';
import { activeRole } from 'app/constants/constants';

@State<ActiveRoleStateModel>({
    name: activeRole,
    defaults: {
        activeRole: null,
    },
})
@Injectable()
export class ActiveRoleState {
    constructor(public _enumsService: EnumsService) {}

    @Selector()
    static activeRole(
        state: ActiveRoleStateModel
    ): SimpleItemModel | undefined {
        return state.activeRole;
    }

    @Action(ActiveRoleGet)
    get(context: StateContext<ActiveRoleStateModel>, params: ActiveRoleGet) {
        return this._enumsService.getRoles().pipe(
            tap((result: SimpleItemModel[]) => {
                const role = result.find((x) => x.id === params.request);
                context.patchState({
                    activeRole: role,
                });
            })
        );
    }
}
