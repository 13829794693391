import { InstitutionDocumentModel, PagedListModel } from 'app/models/all';
import {
    InstitutionDocumentsDeleteRequest,
    InstitutionDocumentsGetPublicRequest,
    InstitutionDocumentsGetRequest,
    InstitutionDocumentsInsertRequest,
    InstitutionDocumentsUpdateRequest,
} from 'app/models/requests/all';

export class InstitutionDocumentsStateModel {
    idocuments?: PagedListModel<InstitutionDocumentModel>;
    filter?: InstitutionDocumentsGetRequest;
}

export class InstitutionDocumentsGet {
    static readonly type = '[Institutions Documents] Get';
    constructor(public request?: InstitutionDocumentsGetRequest) {}
}

export class InstitutionDocumentsGetPublic {
    static readonly type = '[Institutions Documents] GetPublic';
    constructor(public request?: InstitutionDocumentsGetPublicRequest) {}
}


export class InstitutionDocumentsInsert {
    static readonly type = '[Institutions Documents] Insert';
    constructor(public request: InstitutionDocumentsInsertRequest) {}
}

export class InstitutionDocumentsUpdate {
    static readonly type = '[Institutions Documents] Update';
    constructor(public request: InstitutionDocumentsUpdateRequest) {}
}

export class InstitutionDocumentsDelete {
    static readonly type = '[Institutions Documents] Delete';
    constructor(public request: InstitutionDocumentsDeleteRequest) {}
}
