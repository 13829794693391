import { NgModule } from "@angular/core";
import { DeletePromptModalComponent } from "./delete-prompt-modal.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        TranslateModule.forChild({
            extend: true
        })
    ],
    declarations: [
        DeletePromptModalComponent,
    ],
    exports: [
        DeletePromptModalComponent
    ]
})

export class DeletePromptModule {}