import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { InstitutionDocumentModel, PagedListModel } from 'app/models/all';
import {
    InstitutionDocumentsDeleteRequest,
    InstitutionDocumentsGetPublicRequest,
    InstitutionDocumentsGetRequest,
    InstitutionDocumentsInsertRequest,
    InstitutionDocumentsUpdateRequest,
} from 'app/models/requests/institution-document.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class InstitutionDocumentsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: InstitutionDocumentsGetRequest) {
        return this.sendGetRequest<PagedListModel<InstitutionDocumentModel>>(
            'institutiondocuments/filter',
            request
        );
    }

    getPublic(request: InstitutionDocumentsGetPublicRequest){
        return this.sendGetRequest<PagedListModel<InstitutionDocumentModel>>(
            'institutiondocuments/getpublicdocuments',
            request
        );
    }


    insert(request: InstitutionDocumentsInsertRequest) {
        return this.sendPostRequest('institutiondocuments', request);
    }

    update(request: InstitutionDocumentsUpdateRequest) {
        return this.sendPutRequest('institutiondocuments', request);
    }

    delete(request: InstitutionDocumentsDeleteRequest) {
        return this.sendDeleteRequest('institutiondocuments', request);
    }
}
