import {
    BusinessUnitModel,
    PagedListModel,
    SimpleItemModel,
} from 'app/models/all';
import {
    BusinessUnitsGetRequest,
    BusinessUnitsInsertRequest,
    BusinessUnitsUpdateRequest,
    BusinessUnitsDeleteRequest,
    GetByUserIdRequest,
    BusinessUnitsGetSelectListRequest,
} from 'app/models/requests/all';

export class BusinessUnitsStateModel {
    businessUnits?: PagedListModel<BusinessUnitModel>;
    businessUnitsSelectList?: Array<SimpleItemModel>;
    filter?: BusinessUnitsGetRequest;
}

export class BusinessUnitsGet {
    static readonly type = '[Business Units] Get';
    constructor(public request?: BusinessUnitsGetRequest) {}
}

export class BusinessUnitsGetSelectList {
    static readonly type = '[Business Units] GetSelectList';
    constructor(public request?: BusinessUnitsGetSelectListRequest) {}
}

export class BusinessUnitsGetById {
    static readonly type = '[Business Units] GetById';
    constructor(public request?: BusinessUnitsGetRequest) {}
}

export class BusinessUnitsGetByUserId {
    static readonly type = '[Business Units] GetByUserId';
    constructor(public request: GetByUserIdRequest) {}
}

export class BusinessUnitsInsert {
    static readonly type = '[Business Units] Insert';
    constructor(public request: BusinessUnitsInsertRequest) {}
}

export class BusinessUnitsUpdate {
    static readonly type = '[Business Units] Update';
    constructor(public request: BusinessUnitsUpdateRequest) {}
}

export class BusinessUnitsDelete {
    static readonly type = '[Business Units] Delete';
    constructor(public request: BusinessUnitsDeleteRequest) {}
}
