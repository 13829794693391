import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PagedListModel, PersonModel } from 'app/models/all';
import { EventModel } from 'app/models/event.model';
import { EventsService } from 'app/services/all';
import { tap } from 'rxjs';
import {
    EventsDelete,
    EventsGet,
    EventsGetCoordinators,
    EventsGetPublic,
    EventsInsert,
    EventsStateModel,
    EventsUpdate,
} from './events.actions';
import { EventsGetPublicRequest } from 'app/models/requests/events.request';

@State<EventsStateModel>({
    name: 'events',
    defaults: {},
})
@Injectable()
export class EventsState {
    constructor(private service: EventsService) {}

    @Selector()
    static events(
        state: EventsStateModel
    ): PagedListModel<EventModel> | undefined {
        return state.events;
    }
    @Selector()
    static coordinators(state: EventsStateModel): PersonModel[] {
        return state.coordinators;
    }

    @Action(EventsGet)
    get(context: StateContext<EventsStateModel>, params: EventsGet) {
        return this.service.get(params.request).pipe(
            tap((result: PagedListModel<EventModel>) => {
                context.patchState({
                    events: result,
                    filter: params.request,
                });
            })
        );
    }


    @Action(EventsGetPublic)
    getPublic(context: StateContext<EventsStateModel>, params: EventsGetPublic) {
        return this.service.getPublic(params.request).pipe(
            tap((result: PagedListModel<EventModel>) => {
                context.patchState({
                    events: result,
                    filter: null,
                });
            })
        );
    }


    @Action(EventsGetCoordinators)
    getCoordinators(
        context: StateContext<EventsStateModel>,
        params: EventsGetCoordinators
    ) {
        return this.service.getCoordinators(params.request).pipe(
            tap((result: PersonModel[]) => {
                context.patchState({
                    coordinators: result,
                });
            })
        );
    }

    @Action(EventsInsert)
    insert(context: StateContext<EventsStateModel>, params: EventsInsert) {
        return this.service.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new EventsGet(previousState.filter));
            })
        );
    }

    @Action(EventsUpdate)
    update(context: StateContext<EventsStateModel>, params: EventsUpdate) {
        return this.service.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new EventsGet(previousState.filter));
            })
        );
    }

    @Action(EventsDelete)
    delete(context: StateContext<EventsStateModel>, params: EventsDelete) {
        return this.service.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new EventsGet(previousState.filter));
            })
        );
    }
}
