import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { PreschoolDocumentTypeModel, PagedListModel } from '../models/all';
import { ApiService, StorageService } from './all';
import {
    PreschoolDocumentTypeGetRequest,
    PreschoolDocumentTypeInsertRequest,
    PreschoolDocumentTypeUpdateRequest,
    PreschoolDocumentTypeDeleteRequest,
} from '../models/requests/all';

@Injectable({ providedIn: 'root' })
export class PreschoolDocumentTypesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: PreschoolDocumentTypeGetRequest) {
        return this.sendGetRequest<PagedListModel<PreschoolDocumentTypeModel>>(
            'preschooldocumenttypes/filter',
            request
        );
    }

    insert(request: PreschoolDocumentTypeInsertRequest) {
        return this.sendPostRequest<PreschoolDocumentTypeModel>(
            'preschooldocumenttypes',
            request
        );
    }

    update(request: PreschoolDocumentTypeUpdateRequest) {
        return this.sendPutRequest('preschooldocumenttypes', request);
    }

    delete(request: PreschoolDocumentTypeDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('preschooldocumenttypes', request);
    }
}
