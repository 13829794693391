import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChildMonitoringModel } from 'app/models/child-monitoring.model';
import { PagedListModel } from 'app/models/paged-list.model';
import {
    ChildMonitoringDeleteRequest,
    ChildMonitoringGetChildrenRequest,
    ChildMonitoringGetDataById,
    ChildMonitoringGetRequest,
    ChildMonitoringInsertRequest,
    ChildMonitoringUpdateRequest,
} from 'app/models/requests/all';
import { SimpleItemModel } from 'app/models/simple-item.model';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ChildMonitoringService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: ChildMonitoringGetRequest) {
        return this.sendGetRequest<PagedListModel<ChildMonitoringModel>>(
            'childmonitoring/filter',
            request
        );
    }

    getDataById(request: ChildMonitoringGetDataById) {
        return this.sendGetRequest<any[]>(
            'childmonitoring/getdatabyid',
            request
        );
    }

    getDataByChildId(request: ChildMonitoringGetDataById) {
        return this.sendGetRequest<ChildMonitoringModel[]>(
            'childmonitoring/getdatabychildid',
            request
        );
    }

    getChildren(request: ChildMonitoringGetChildrenRequest) {
        return this.sendGetRequest<any[]>(
            'childmonitoring/getchildren',
            request
        );
    }

    getChildrenSimple(request: ChildMonitoringGetChildrenRequest) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'childmonitoring/getchildrensimple',
            request
        );
    }

    insert(request: ChildMonitoringInsertRequest) {
        return this.sendPostRequest<any>('childmonitoring', request);
    }

    update(request: ChildMonitoringUpdateRequest) {
        return this.sendPutRequest<any>('childmonitoring', request);
    }

    delete(request: ChildMonitoringDeleteRequest) {
        return this.sendDeleteRequest<any>('childmonitoring', request);
    }
}
