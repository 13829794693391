import { NgModule } from "@angular/core";
import { AddressComponent } from "./address.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDividerModule } from "@angular/material/divider";
import { MatModule } from "app/shared/mat-modules.module";

@NgModule({
    declarations:[
        AddressComponent
    ],
    imports: [
        TranslateModule.forChild({
            extend: true
        }),
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatModule
    ],
    exports: [
        AddressComponent
    ],
})
export class AddressModule {}