export const environment = {
    production: true,
    defaultLanguage: 'bs',
    maxFilesNumber:5,
    maxFileSize: 2024,
    appName: 'ePreschool',
    baseApiUrl: 'https://api.preschool.breakpoint.ba/api',
    hub: 'https://api.preschool.breakpoint.ba/Hub',
    localizationFilesPath: '`../assets/i18n/',
    localizationApiUrl: 'https://localization.breakpoint.ba/api/translations',
    publicWebPagesPrefix: 'vrtic'
};
