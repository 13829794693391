import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { TokenService } from '../services/all';
import { AuthState } from '../stores/all';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
        private tokenService: TokenService
    ) {}

    canActivate(): boolean {
        const token = this.store.selectSnapshot(AuthState.token);
        if (token && !this.tokenService.isExpired(token.toString())) {
            return true;
        }
        this.router.navigate(['/sign-in'], { state: { sessionExpired: true }});
        return false;
    }
}
