import { BaseRequest } from './base.request';

export class GetByIdRequest implements BaseRequest {
    constructor(public Id: number) {}
    toParams(): Object {
        return {
            Id: this.Id,
        };
    }
    toBody(): Object {
        return {
            Id: this.Id,
        };
    }
}
