import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    AppUsersStateModel,
    AppUsersGet,
    AppUsersInsert,
    AppUsersUpdate,
    AppUserDeactivate,
} from './app-users.actions';
import { PagedListModel } from 'app/models/all';
import { AppUsersService } from 'app/services/all';

@State<AppUsersStateModel>({
    name: 'users',
    defaults: {},
})
@Injectable()
export class AppUsersState {
    constructor(private appUsersService: AppUsersService) {}

    @Selector()
    static users(state: AppUsersStateModel): PagedListModel<any> | undefined {
        return state.users;
    }

    @Action(AppUsersGet)
    get(context: StateContext<AppUsersStateModel>, params: AppUsersGet) {
        return this.appUsersService.get(params.request).pipe(
            tap((result: PagedListModel<any>) => {
                context.patchState({
                    users: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(AppUsersInsert)
    insert(context: StateContext<AppUsersStateModel>, params: AppUsersInsert) {
        return this.appUsersService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new AppUsersGet(previousState.filter));
            })
        );
    }

    @Action(AppUsersUpdate)
    update(context: StateContext<AppUsersStateModel>, params: AppUsersUpdate) {
        /*         return this.appUsersService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CitiesGet(previousState.filter));
            })
             );
 */
    }

    @Action(AppUserDeactivate)
    delete(
        context: StateContext<AppUsersStateModel>,
        params: AppUserDeactivate
    ) {
        return this.appUsersService.deactivate(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new AppUsersGet(previousState.filter));
            })
        );
    }
}
