import { SimpleItemModel } from '../../models/all';
export class EnumsStateModel {
    simpleItemGenders?: SimpleItemModel[];
    simpleItemParentshipTypes?: SimpleItemModel[];
    simpleItemQualifications?: SimpleItemModel[];
    simpleItemVisibilities?: SimpleItemModel[];
    simpleItemAnnouncements?: SimpleItemModel[];
}

export class GetGenders {
    static readonly type = '[EnumType] GetGenders';
    constructor() {}
}

export class GetParentshipTypes {
    static readonly type = '[EnumType] GetParentshipTypes';
    constructor() {}
}

export class GetQualifications {
    static readonly type = '[EnumType] GetQualifications';
    constructor() {}
}

export class GetVisibilities {
    static readonly type = '[EnumType] GetVisibilities';
    constructor() {}
}

export class GetAnnouncements {
    static readonly type = '[EnumType] GetAnnouncements';
    constructor() {}
}
