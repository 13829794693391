import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import { GetDocumentsForChildRequest, PersonFileStorageInsertRequest } from '../models/requests/all';
import { FileStorageModel } from 'app/models/file-storage.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    insertfilestorage(request: PersonFileStorageInsertRequest) {
        return this.sendPostRequest<any>('personfilestorage', request);
    }

    getdocumentsforchild(request: GetDocumentsForChildRequest): Observable<any> {
        return this.sendGetRequest<any>(
            'personfilestorage/getdocumentsforchild',
            request
        );
    }
}
