import { MatPaginatorIntl } from '@angular/material/paginator';
export function getTranslatePaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Broj stavki po stranici:';
    paginatorIntl.nextPageLabel = 'Sljedeća stranica';
    paginatorIntl.previousPageLabel = 'Predhodna stranica';

    return paginatorIntl;
}
