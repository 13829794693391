import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TransferService {
    private data = new BehaviorSubject<any>(null);

    _data = this.data.asObservable();

    constructor() {}

    setPillMenuIndex(file: any) {
        this.data.next(file);
    }
}
