import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'cleanHtml',
})
export class CleanHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        // Define the tags to allow
        const allowedTags = ['br', 'strong', 'img', 'p', 'b', 'font', 'ul', 'ol', 'li'];
        
        // Regular expression to match all HTML tags
        const regex = /<[^>]*>/g;

        // Replace all tags with allowed tags
        const cleanedValue = value.replace(regex, (match) => {
            const tagName = match
                .replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/g, '$1')
                .toLowerCase();
            if (tagName === 'img') {
                // Add class to img tag
                return match.replace(/<img/g, '<img class="w-50 m-3"');
            }
            if (allowedTags.includes(tagName)) {
                return match;
            }
            return '';
        });
        // Sanitize and return the cleaned HTML
        return this.sanitizer.bypassSecurityTrustHtml(cleanedValue.trimEnd());
    }
}
