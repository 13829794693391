<div class="w-96">
<div class="w-full">

  <div class="flex justify-start  py-4  px-4">
    <img src="assets/images/logo/logo.png" class="w-12 h-8 mb-3">
  </div>

  <div class="flex flex-col p-5 w-full items-center mt-1 pb-10">
      <img class="w-32 h-32 mb-3 border-2 border-gray-300 rounded-full shadow-lg" [src]="_data.ProfilePhotoThumbnailUrl" alt="Bonnie image"/>

      <h1 class="mb-4 m-5 text-xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">{{_data.FirstName}}</span> {{_data.LastName}}.</h1>
      <div class="w-full flex  p-5">
        <mat-form-field class="fuse-mat w-full" >
        <mat-select #selectRole  matInput [placeholder]="'ChooseStaffPosition' | translate"> 
          <mat-option *ngFor="let role of _data.Roles" [value]="role.id">{{role.label | translate}}</mat-option>
        </mat-select>
    
          </mat-form-field>
              </div>

              <button
              class=" w-6/12  p-5"
              mat-flat-button
              [color]="'primary'"
              (click)="submitRole(selectRole.value)"
           >
              <span >
                  {{'SignIn' | translate}}
              </span>
             
          </button>

  </div>

</div>
</div>