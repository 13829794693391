import { EventModel, PagedListModel, PersonModel } from 'app/models/all';
import {
    EventGetCoordinatorsRequest,
    EventsDeleteRequest,
    EventsGetByBusinessUnitIdsRequest,
    EventsGetPublicRequest,
    EventsGetRequest,
    EventsInsertRequest,
    EventsUpdateRequest,
} from 'app/models/requests/all';

export class EventsStateModel {
    events?: PagedListModel<EventModel>;
    filter?: EventsGetRequest;
    coordinators?: PersonModel[];
}
export class EventsGet {
    static readonly type = '[Events] Get';
    constructor(public request?: EventsGetRequest) {}
}

export class EventsGetPublic {
    static readonly type = '[Events] GetPublic';
    constructor(public request: EventsGetPublicRequest) {}
}

export class EventsGetCoordinators {
    static readonly type = '[Events] Get Coordinators';
    constructor(public request: EventGetCoordinatorsRequest) {}
}

export class EventsGetByBusinessUnitIds {
    static readonly type = '[Events] Get By Business Unit Ids';
    constructor(public request: EventsGetByBusinessUnitIdsRequest) {}
}

export class EventsInsert {
    static readonly type = '[Events] Insert';
    constructor(public request: EventsInsertRequest) {}
}

export class EventsUpdate {
    static readonly type = '[Events] Update';
    constructor(public request: EventsUpdateRequest) {}
}
export class EventsDelete {
    static readonly type = '[Events] Delete';
    constructor(public request: EventsDeleteRequest) {}
}
