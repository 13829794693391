import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private selectedElement: any;

    setSelectedElement(element: any): void {
        this.selectedElement = element;
    }

    getSelectedElement(): any {
        return this.selectedElement;
    }
}
