import {
    ProgramsGetRequest,
    ProgramsInsertRequest,
    ProgramsUpdateRequest,
    ProgramsDeleteRequest,
} from 'app/models/requests/all';
export class ProgramsStateModel {
    programs?: any;
    filter?: ProgramsGetRequest;
}

export class ProgramsGet {
    static readonly type = '[Programs] Get';
    constructor(public request?: ProgramsGetRequest) {}
}

export class ProgramsInsert {
    static readonly type = '[Programs] Insert';
    constructor(public request: ProgramsInsertRequest) {}
}

export class ProgramsUpdate {
    static readonly type = '[Programs] Update';
    constructor(public request: ProgramsUpdateRequest) {}
}

export class ProgramsDelete {
    static readonly type = '[Programs] Delete';
    constructor(public request: ProgramsDeleteRequest) {}
}
