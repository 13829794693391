<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            id="dropdownIcon"
            class="rounded-full"
            *ngIf="
                showAvatar && (user$ | async).details?.ProfilePhotoThumbnailUrl
            "
            [src]="(user$ | async).details?.ProfilePhotoThumbnailUrl"
        />
        <mat-icon
            *ngIf="
                !showAvatar ||
                !(user$ | async).details?.ProfilePhotoThumbnailUrl
            "
            [svgIcon]="'heroicons_outline:user-circle'"
        ></mat-icon>
    </span>
</button>
<mat-menu class="w-60" [xPosition]="'before'" #userActions="matMenu">
    <ng-container *ngIf="_currentRole?.InstitutionName">
        <button mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:academic-cap'"></mat-icon>
            <span class="flex flex-col leading-none"
                ><span>{{ _currentRole?.InstitutionName }}</span>
                <!-- <span
                    *ngIf="_currentRole?.UnitName"
                    class="mt-1.5 text-xs font-medium"
                    >{{ _currentRole?.UnitName }}</span> -->
            </span>
        </button>
    </ng-container>

    <mat-divider
        *ngIf="_currentRole?.InstitutionName"
        class="my-2"
    ></mat-divider>

    <button mat-menu-item (click)="navigate('/user-settings')">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{ "Profile" | translate }}</span>
    </button>

    <button
        *ngIf="
            (details$ | async)?.Children?.length > 0 &&
            _storageService.getActiveRole() == 'parent'
        "
        mat-menu-item
        [matMenuTriggerFor]="listCard06Menu"
    >
        <mat-icon [svgIcon]="'heroicons_outline:user-group'"></mat-icon>
        <span>{{ "Children" | translate }}</span>
    </button>
    <mat-menu #listCard06Menu="matMenu">
        <button mat-menu-item (click)="navigate('/newenrollment')">
            <mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
            <span>{{ "EnrollmentRequest" | translate }}</span>
        </button>
        <mat-divider class="my-2"></mat-divider>

        <button
            *ngFor="let child of (details$ | async)?.Children"
            mat-menu-item
            (click)="navigate('/children/' + child.id)"
        >
            <ng-container *ngIf="child.profilePhotoThumbnailUrl">
                <img
                    class="w-8 h-8 mr-3 rounded-full"
                    [src]="child.profilePhotoThumbnailUrl"
                />
            </ng-container>

            <ng-container *ngIf="!child.profilePhotoThumbnailUrl">
                <img
                    *ngIf="child?.gender == 1"
                    class="w-8 h-8 mr-3 rounded-full"
                    [src]="'assets/images/avatars/child-avatar-female.png'"
                />

                <img
                    *ngIf="child?.gender == 2"
                    class="w-8 h-8 mr-3 rounded-full"
                    [src]="'assets/images/avatars/child-avatar-male.png'"
                />
            </ng-container>

            {{ child.firstName }} {{ child.lastName }}
        </button>
    </mat-menu>

    <button
        *ngIf="(details$ | async)?.Roles?.length > 1"
        (click)="changeUserRole(user)"
        mat-menu-item
    >
        <mat-icon [svgIcon]="'heroicons_outline:users'"></mat-icon>
        <span>{{ "Change" | translate }}</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{ "SignOut" | translate }}</span>
    </button>
</mat-menu>
