import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { activeRole, institutionId } from 'app/constants/constants';
import { UserModel } from 'app/models/user.model';
import { NavigationRoleService } from 'app/services/navigation-role.service';
import { StorageService } from 'app/services/storage.service';
import { ActiveRoleGet, AuthRefresh, AuthState } from 'app/stores/all';
import { tap } from 'rxjs';

@Component({
    selector: 'app-choose-roles',
    templateUrl: './choose-roles.component.html',
    styleUrls: ['./choose-roles.component.scss'],
})
export class ChooseRolesComponent implements OnInit {
    constructor(
        public _dialogRef: MatDialogRef<ChooseRolesComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: UserModel,
        public _store: Store,
        private _roleNavigationService: NavigationRoleService,
        private _storageService: StorageService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    submitRole(x: number) {
        if (this._data.AspNetUserRoles.find((r) => r.RoleId == x) == null) {
            return;
        } else {
            const institutionIdNum = this._data.AspNetUserRoles.find(
                (r) => r.RoleId == x
            ).InstitutionId;

            const unitId = this._data.AspNetUserRoles.find(
                (r) => r.RoleId == x
            ).UnitId;

            if (institutionIdNum != null) {
                this._storageService.setItem(
                    institutionId,
                    institutionIdNum.toString()
                );
                this._store.dispatch(
                    new AuthRefresh({
                        ...this._store.selectSnapshot(AuthState.details),
                        InstitutionId: institutionIdNum.toString(),
                    })
                );
            }

            if (unitId != null) {
                this._storageService.setItem('unitId', unitId.toString());
            }

            this._store
                .dispatch(new ActiveRoleGet(x))
                .pipe(
                    tap((role: any) => {
                        this._store.dispatch(
                            new AuthRefresh({
                                ...this._store.selectSnapshot(
                                    AuthState.details
                                ),
                                ActiveRole: role.activeRole?.activeRole?.label,
                            })
                        );

                        // postavljanje trenutne školske godine na instituciji!
                        this._storageService.setItem(
                            'currentSchoolYear',
                            this._store
                                .selectSnapshot(AuthState.details)
                                .SchoolYear.toString()
                        );

                        this._storageService.setItem(
                            activeRole,
                            JSON.stringify(role.activeRole?.activeRole?.label)
                        );
                    })
                )
                .subscribe((_) => {
                    this._roleNavigationService.getRoleNavigation();

                    this.closeDialog();
                });
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
