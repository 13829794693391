import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

export abstract class ModalState {}

export class ModalShownState implements ModalState {
  constructor(public payload?: any | null) {}
}

export class ModalHiddenState implements ModalState {
  constructor(public result?: any | null) {}
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private stateBehaviourSubject: BehaviorSubject<ModalState>;

  constructor() {
    this.stateBehaviourSubject = new BehaviorSubject<ModalState>(
      new ModalHiddenState()
    );
  }

  showModal(result?: any | null) {
    this.stateBehaviourSubject.next(new ModalShownState(result));
  }

  hideModal(result?: any | null) {
    this.stateBehaviourSubject.next(new ModalHiddenState(result));
  }

  isVisible(): boolean {
    return this.stateBehaviourSubject.value instanceof ModalShownState;
  }

  subscribe(next: (value: ModalState) => void): Subscription {
    return this.stateBehaviourSubject.subscribe(next);
  }

  unsubscribe(): void {
    this.stateBehaviourSubject.unsubscribe();
  }
}
