import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiService, StorageService } from './all';
import { HttpClient } from '@angular/common/http';
import {
    DailyActivitiesInputsDeleteRequest,
    DailyActivitiesInputsGetRequest,
} from 'app/models/requests/daily-activities-inputs.request';
import { DailyActivitiesInputModel } from 'app/models/daily-activities-inputs.model';
import { GetByIdRequest } from 'app/models/requests/by-id.request';

@Injectable({
    providedIn: 'root',
})
export class DailyActivitiesInputsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: DailyActivitiesInputsGetRequest) {
        return this.sendGetRequest<DailyActivitiesInputModel[]>(
            'dailyactivitiesinputs/getbyinstitution',
            request
        );
    }

    getForParent(){
        return  this.sendGetRequest<any>('dailyactivitiesinputs/getforparent');
    }
    
    getById(request: GetByIdRequest){
        return this.sendGetRequest<DailyActivitiesInputModel>('dailyactivitiesinputs', request);
    }
    
    getByIdForCalendar(request: GetByIdRequest){
        return this.sendGetRequest<DailyActivitiesInputModel>('dailyactivitiesinputs/getbyidforcalendar', request);
    }

    delete(request: DailyActivitiesInputsDeleteRequest) {
        return this.sendDeleteRequest('dailyactivitiesinputs', request);
    }
}
