import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { activeRole } from 'app/constants/constants';
import { SimpleItemModel } from 'app/models/simple-item.model';
import { StorageService } from 'app/services/storage.service';
import { ActiveRoleState } from 'app/stores/all';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(private _storage: StorageService, private router: Router) {}
    @Select(ActiveRoleState.activeRole) role$: Observable<SimpleItemModel>;
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isAuthorized = this.isAuthorized(route);

        if (isAuthorized) {
            return true;
        } else {
            this.router.navigate(['401-unauthorized']);
        }
    }

    isAuthorized(route: ActivatedRouteSnapshot): boolean {
        const activeRoleItem = this._storage.getItem(activeRole);
        const expectedRoles = route.data.expectedRoles;
        return expectedRoles.some((role: string) => role == activeRoleItem);
    }
}
