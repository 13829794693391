<div class="flex flex-col items-center max-w-80 w-full 8 filter-info">
    <div
        class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600"
    >
        <mat-icon
            class="icon-size-10 text-primary-500 dark:text-primary-300"
            [svgIcon]="'heroicons_solid:information-circle'"
        ></mat-icon>
    </div>
    <div class="text-left text-lg text-secondary mt-3">
        {{ message }}
    </div>
    <div class="grid grid-cols-2 mt-9 gap-4">
        <a mat-button [color]="'warn'" (click)="closeDialog()">
            {{ "Cancel" | translate }}
        </a>

        <a mat-button [color]="'primary'" (click)="navigate()">
            {{ "EnrollmentRequest" | translate }}
        </a>
    </div>
</div>
