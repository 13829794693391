import { ActivityTypeModel } from 'app/models/all';
import {
    ActivityTypesDeleteRequest,
    ActivityTypesInsertRequest,
    ActivityTypesUpdateRequest,
} from 'app/models/requests/all';

export class ActivitiesStateModel {
    activities?: ActivityTypeModel[];
}

export class ActivitiesGet {
    static readonly type = '[Activities] Get';
    constructor() {}
}
export class ActivitiesInsert {
    static readonly type = '[Activities] Insert';
    constructor(public request: ActivityTypesInsertRequest) {}
}
export class ActivitiesUpdate {
    static readonly type = '[Activities] Update';
    constructor(public request: ActivityTypesUpdateRequest) {}
}

export class ActivitiesDelete {
    static readonly type = '[Activities] Delete';
    constructor(public request: ActivityTypesDeleteRequest) {}
}
