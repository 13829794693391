import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ApiService, StorageService } from './all';
import { Injectable } from '@angular/core';
import { SimpleItemModel } from 'app/models/simple-item.model';
import { SchoolYearModel } from 'app/models/all';
import { SchoolYearsDeleteRequest, SchoolYearsInsertRequest } from 'app/models/requests/all';

@Injectable({ providedIn: 'root' })
export class SchoolYearsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getSchoolYears(preschoolInstitutionId : number) {
        return this.sendGetRequest<SimpleItemModel[]>('schoolyears/getselectlist?preschoolInstitutionId=' + preschoolInstitutionId);
    }

    getSchoolYearsByInstitutionId(preschoolInstitutionId : number){
        return this.sendGetRequest<SchoolYearModel[]>('schoolyears/getbyinstitutionid?preschoolInstitutionId=' + preschoolInstitutionId)
    }

    getLastByInstitutionId(id : number){
        return this.sendGetRequest<SchoolYearModel>('schoolyears/getlastbyinstitutionid?id=' + id);
    }

    postSchoolYear(request : SchoolYearsInsertRequest) {
        return this.sendPostRequest('schoolyears', request);
    }

    removeSchoolYear(request : SchoolYearsDeleteRequest) {
        return this.sendDeleteRequest('schoolyears', request);
    }
}
