import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    CountriesStateModel,
    CountriesGet,
    CountriesInsert,
    CountriesUpdate,
    CountriesDelete,
} from './countries.actions';
import { CountryModel, PagedListModel } from 'app/models/all';
import { CountriesService } from 'app/services/all';

@State<CountriesStateModel>({
    name: 'countries',
    defaults: {},
})
@Injectable()
export class CountriesState {
    constructor(private countriesService: CountriesService) {}

    @Selector()
    static countries(
        state: CountriesStateModel
    ): PagedListModel<CountryModel> | undefined {
        return state.countries;
    }

    @Action(CountriesGet)
    get(context: StateContext<CountriesStateModel>, params: CountriesGet) {
        return this.countriesService.get(params.request).pipe(
            tap((result: PagedListModel<CountryModel>) => {
                context.patchState({
                    countries: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(CountriesInsert)
    insert(
        context: StateContext<CountriesStateModel>,
        params: CountriesInsert
    ) {
        return this.countriesService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CountriesGet(previousState.filter));
            })
        );
    }

    @Action(CountriesUpdate)
    update(
        context: StateContext<CountriesStateModel>,
        params: CountriesUpdate
    ) {
        return this.countriesService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CountriesGet(previousState.filter));
            })
        );
    }

    @Action(CountriesDelete)
    delete(
        context: StateContext<CountriesStateModel>,
        params: CountriesDelete
    ) {
        return this.countriesService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new CountriesGet(previousState.filter));
            })
        );
    }
}
