import { NgModule } from "@angular/core";
import { FormControlErrorComponent } from "./form-control-error.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports:[
      CommonModule,
      TranslateModule
    ],
    declarations: [
        FormControlErrorComponent,
    ],
    exports: [
      FormControlErrorComponent
    ],
})
export class FormControlErrorModule {}