<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </button>
    <div
        class="absolute inset-0 flex items-center shrink-0 z-99 bg-card"
        *ngIf="opened"
        @slideInTop
        @slideOutTop
    >
        <mat-icon
            class="absolute ml-6 sm:ml-8"
            [svgIcon]="'heroicons_outline:search'"
        ></mat-icon>
        <input
            class="w-full h-full px-16 sm:px-18"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search' | translate"
            (keydown)="onKeydown($event)"
            #barSearchInput
        />
        <mat-autocomplete
            class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            #matAutocomplete="matAutocomplete"
        >
            <mat-option
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="
                    resultSets &&
                    !resultSets?.events?.length &&
                    !resultSets?.announcements?.length &&
                    !resultSets?.documents?.length
                "
            >
                {{ "NoResultsFound" | translate }}
            </mat-option>

            <ng-container *ngIf="resultSets?.announcements?.length">
                <div class="grid grid-flow-col auto-cols-max w-full">
                    <p class="text-secondary m-1">
                        {{ "Announcements" | translate }}
                    </p>
                </div>

                <ng-container
                    *ngFor="
                        let resultSet of resultSets?.announcements;
                        trackBy: trackByFn
                    "
                >
                    <ng-container>
                        <mat-option
                            (click)="openAnnouncement(resultSet)"
                            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                        >
                            <ng-container>
                                <div class="grid grid-flow-col auto-cols-max">
                                    <div
                                        class="flex shrink-0 items-center justify-center w-8 h-8 overflow-hidden"
                                    >
                                        <mat-icon
                                            *ngIf="
                                                resultSet?.announcementType == 1
                                            "
                                            class="m-0 icon-size-5 dark:text-primary-100"
                                            [svgIcon]="
                                                'heroicons_outline:information-circle'
                                            "
                                        ></mat-icon>

                                        <mat-icon
                                            *ngIf="
                                                resultSet?.announcementType == 2
                                            "
                                            class="m-0 icon-size-5 dark:text-primary-100"
                                            [svgIcon]="
                                                'heroicons_outline:calendar'
                                            "
                                        ></mat-icon>

                                        <mat-icon
                                            *ngIf="
                                                resultSet?.announcementType == 3
                                            "
                                            class="m-0 icon-size-5 dark:text-primary-100"
                                            [svgIcon]="
                                                'heroicons_outline:exclamation-circle'
                                            "
                                        ></mat-icon>
                                    </div>
                                    <div
                                        class="ml-3 mt-1 truncate text-extrabold"
                                    >
                                        <span
                                            [innerHTML]="resultSet?.title"
                                        ></span>
                                    </div>
                                    <div
                                        class="ml-3 mt-1 max-w-96 md:max-w-xs truncate text-secondary"
                                    >
                                        <span
                                            [innerHTML]="resultSet?.shortly"
                                        ></span>
                                    </div>
                                    <span
                                        class="ml-9 mt-1 max-w-96 md:max-w-xs truncate text-secondary"
                                    >
                                        {{ resultSet?.institutionName }} -
                                        {{
                                            resultSet?.unitName == "null"
                                                ? ""
                                                : resultSet?.unitName
                                        }}
                                    </span>

                                    <div
                                        class="ml-8 mt-1 truncate text-extrabold text-secondary"
                                    >
                                        <span
                                            >{{ "Published" | translate }} :
                                        </span>
                                        <span
                                            [innerHTML]="
                                                resultSet?.createdAt
                                                    | dateFormat
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-option>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="resultSets?.events?.length">
                <mat-divider></mat-divider>
                <div class="grid grid-flow-col auto-cols-max pt-3 px-6">
                    <p class="text-secondary m-1 mt-3">
                        {{ "Events" | translate }}
                    </p>
                </div>
                <ng-container
                    *ngFor="
                        let resultSet of resultSets?.events;
                        trackBy: trackByFn
                    "
                >
                    <ng-container>
                        <mat-option
                            (click)="openEvent(resultSet)"
                            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                        >
                            <ng-container>
                                <div class="grid grid-flow-col auto-cols-max">
                                    <div
                                        class="flex shrink-0 items-center justify-center w-8 h-8 overflow-hidden"
                                    >
                                        <mat-icon
                                            class="m-0 icon-size-5 dark:text-primary-100"
                                            [svgIcon]="
                                                'heroicons_outline:calendar'
                                            "
                                        ></mat-icon>
                                    </div>
                                    <div
                                        class="ml-3 mt-1 truncate text-extrabold"
                                    >
                                        <span
                                            [innerHTML]="resultSet?.name"
                                        ></span>
                                    </div>
                                    <span
                                        class="ml-9 mt-1 max-w-96 md:max-w-xs truncate text-secondary"
                                    >
                                        {{ resultSet?.institutionName }} -
                                        {{
                                            resultSet?.unitName == "null"
                                                ? ""
                                                : resultSet?.unitName
                                        }}
                                    </span>

                                    <div
                                        class="ml-3 mt-1 truncate text-secondary"
                                    >
                                        <span
                                            [innerHTML]="
                                                ('DateOfEvent' | translate) +
                                                ' : ' +
                                                (resultSet?.dateOfEvent
                                                    | dateFormat)
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-option>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="resultSets?.documents?.length">
                <div class="grid grid-flow-col auto-cols-max pt-3 px-6">
                    <p class="text-secondary m-1">
                        {{ "Documents" | translate }}
                    </p>
                </div>

                <ng-container
                    *ngFor="
                        let resultSet of resultSets?.documents;
                        trackBy: trackByFn
                    "
                >
                    <ng-container>
                        <mat-option
                            [value]="null"
                            (click)="download(resultSet?.absouluteUrl)"
                            class="group relative mb-1 py-0 px-6 text-md rounded-md"
                        >
                            <ng-container>
                                <div class="grid grid-flow-col auto-cols-max">
                                    <div
                                        class="flex shrink-0 items-center justify-center w-8 h-8 overflow-hidden"
                                    >
                                        <a
                                            mat-icon-button
                                            [href]="resultSet?.absouluteUrl"
                                            ><mat-icon
                                                [svgIcon]="
                                                    'heroicons_outline:folder'
                                                "
                                            ></mat-icon
                                        ></a>
                                    </div>
                                    <div
                                        class="ml-3 mt-1 truncate text-extrabold"
                                    >
                                        <span
                                            [innerHTML]="resultSet?.name"
                                        ></span>
                                    </div>
                                    <div
                                        class="ml-3 mt-1 truncate text-secondary"
                                    >
                                        <span
                                            [innerHTML]="resultSet?.note"
                                        ></span>
                                    </div>
                                    <span
                                        class="ml-9 mt-1 max-w-96 md:max-w-xs truncate text-secondary"
                                    >
                                        {{ resultSet?.institutionName }}
                                        {{
                                            resultSet?.unitName == "null"
                                                ? ""
                                                : resultSet?.unitName
                                        }}
                                    </span>
                                </div>
                            </ng-container>
                        </mat-option>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
        <button
            class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
            mat-icon-button
            (click)="close()"
        >
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</ng-container>
