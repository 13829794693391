import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EvaluationItemModel } from 'app/models/evaluation-items.model';
import {
    InstitutionEvaluationItemsDeleteRequest,
    InstitutionEvaluationItemsGetByInstitutionIdRequest,
    InstitutionEvaluationItemsGetRequest,
    InstitutionEvaluationItemsInsertRequest,
    InstitutionEvaluationItemsUpdateRequest,
} from 'app/models/requests/all';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class InstitutionEvaluationItemsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getByInstitutionId(
        request: InstitutionEvaluationItemsGetByInstitutionIdRequest
    ) {
        return this.sendGetRequest<EvaluationItemModel[]>(
            'institutionevaluationitems/getbyinstitutionid',
            request
        );
    }

    get(request: InstitutionEvaluationItemsGetRequest) {
        return this.sendGetRequest<any>('institutionevaluationitems', request);
    }

    insert(request: InstitutionEvaluationItemsInsertRequest) {
        return this.sendPostRequest<any>('institutionevaluationitems', request);
    }

    update(request: InstitutionEvaluationItemsUpdateRequest) {
        return this.sendPutRequest<any>('institutionevaluationitems', request);
    }

    delete(request: InstitutionEvaluationItemsDeleteRequest) {
        return this.sendDeleteRequest('institutionevaluationitems', request);
    }
}
