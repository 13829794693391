import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    PreschoolDocumentTypesStateModel,
    PreschoolDocumentTypesGet,
    PreschoolDocumentTypesInsert,
    PreschoolDocumentTypesUpdate,
    PreschoolDocumentTypesDelete,
} from './preschool-document-types.actions';
import { PreschoolDocumentTypeModel, PagedListModel } from 'app/models/all';
import { PreschoolDocumentTypesService } from 'app/services/all';

@State<PreschoolDocumentTypesStateModel>({
    name: 'preschoolDocuments',
    defaults: {},
})
@Injectable()
export class PreschoolDocumentTypesState {
    constructor(
        private preschooldocumentTypesService: PreschoolDocumentTypesService
    ) {}

    @Selector()
    static preschoolDocuments(
        state: PreschoolDocumentTypesStateModel
    ): PreschoolDocumentTypeModel[] | undefined {
        return state.preschoolDocuments?.items;
    }

    @Action(PreschoolDocumentTypesGet)
    get(
        context: StateContext<PreschoolDocumentTypesStateModel>,
        params: PreschoolDocumentTypesGet
    ) {
        return this.preschooldocumentTypesService.get(params.request).pipe(
            tap((result: PagedListModel<PreschoolDocumentTypeModel>) => {
                context.patchState({
                    preschoolDocuments: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(PreschoolDocumentTypesInsert)
    insert(
        context: StateContext<PreschoolDocumentTypesStateModel>,
        params: PreschoolDocumentTypesInsert
    ) {
        return this.preschooldocumentTypesService.insert(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(
                    new PreschoolDocumentTypesGet(previousState.filter)
                );
            })
        );
    }

    @Action(PreschoolDocumentTypesUpdate)
    update(
        context: StateContext<PreschoolDocumentTypesStateModel>,
        params: PreschoolDocumentTypesUpdate
    ) {
        return this.preschooldocumentTypesService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();
                context.dispatch(
                    new PreschoolDocumentTypesGet(previousState.filter)
                );
            })
        );
    }

    @Action(PreschoolDocumentTypesDelete)
    delete(
        context: StateContext<PreschoolDocumentTypesStateModel>,
        params: PreschoolDocumentTypesDelete
    ) {
        return this.preschooldocumentTypesService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(
                    new PreschoolDocumentTypesGet(previousState.filter)
                );
            })
        );
    }
}
