import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InstitutionDocumentModel, PagedListModel } from 'app/models/all';
import { InstitutionDocumentsService } from 'app/services/institution-documents.service';
import { tap } from 'rxjs';
import {
    InstitutionDocumentsDelete,
    InstitutionDocumentsGet,
    InstitutionDocumentsGetPublic,
    InstitutionDocumentsStateModel,
} from './institution-documents.actions';

@State<InstitutionDocumentsStateModel>({
    name: 'idocuments',
    defaults: {},
})
@Injectable()
export class InstitutionsDocumentsState {
    constructor(private service: InstitutionDocumentsService) {}

    @Selector()
    static documents(
        state: InstitutionDocumentsStateModel
    ): PagedListModel<InstitutionDocumentModel> | undefined {
        return state.idocuments;
    }

    @Action(InstitutionDocumentsGet)
    get(
        context: StateContext<InstitutionDocumentsStateModel>,
        params: InstitutionDocumentsGet
    ) {
        return this.service.get(params.request).pipe(
            tap((result: PagedListModel<InstitutionDocumentModel>) => {
                context.patchState({
                    idocuments: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(InstitutionDocumentsGetPublic)
    getPublic(
        context: StateContext<InstitutionDocumentsStateModel>,
        params: InstitutionDocumentsGetPublic
    ) {
        return this.service.getPublic(params.request).pipe(
            tap((result: PagedListModel<InstitutionDocumentModel>) => {
                context.patchState({
                    idocuments: result,
                    filter: null,
                });
            })
        );
    }


    @Action(InstitutionDocumentsDelete)
    delete(
        context: StateContext<InstitutionDocumentsStateModel>,
        params: InstitutionDocumentsDelete
    ) {
        return this.service.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();
                context.dispatch(
                    new InstitutionDocumentsGet(previousState.filter)
                );
            })
        );
    }
}
